import React, { useState } from "react";
import {
  Grid,
  List,
  ListItemText,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { Colors } from "../../styles/theme";
import { FooterTitle } from "../../styles/footer";
import Termsmodal from "../../pages/Termsmodal";
import { useNavigate } from "react-router-dom";

export default function Footer() {
  const navigate = useNavigate();
  const [openTermsModal, setOpenTermsModal] = useState(false);

  const handleOpenTermsModal = () => {
    setOpenTermsModal(true);
  };

  const handleCloseTermsModal = () => {
    setOpenTermsModal(false);
  };

  const handleAboutus = async () => {
    navigate("/aboutus"); // Navigate to the Aboutus page
  };

  const handleServices = async () => {
    navigate("/services"); // Navigate to the Services page
  };
  
  const handleContact = async () => {
    navigate("/contactus"); // Navigate to the ContactUs page
  };

  return (
    <Box
      sx={{
        background: Colors.shaft,
        color: Colors.white,
        p: { xs: 4, md: 10 },
        pt: 12,
        pb: 12,
        fontSize: { xs: "12px", md: "14px" },
      }}
    >
      <Grid container spacing={2} justifyContent="center">
        <Grid item md={6} lg={10}>
          <FooterTitle variant="body1">About us</FooterTitle>
          <Typography variant="caption2">
            Learngaadi Driving School has been a pioneer in the field of driving
            training since 2002. Driven by world-class technology & training
            curriculum, Learngaadi Driving School has elevated and redefined the
            standards of driving training in the country.
          </Typography>
          <Box
            sx={{
              mt: 4,
              color: Colors.dove_gray,
            }}
          >
          </Box>
        </Grid>
        <Grid item md={6} lg={2}>
          <FooterTitle variant="body1">information</FooterTitle>
          <List>
            <ListItemText>
              <Typography
                lineHeight={2}
                variant="caption2"
                onClick={handleAboutus}
                style={{ cursor: "pointer" }}
              >
                About Us
              </Typography>
            </ListItemText>
            <ListItemText>
              <Typography
                lineHeight={2}
                variant="caption2"
                onClick={handleServices}
                style={{ cursor: "pointer" }}
              >
                Services
              </Typography>
            </ListItemText>
            <ListItemText>
              <Typography
                lineHeight={2}
                variant="caption2"
                onClick={handleContact}
                style={{ cursor: "pointer" }}
              >
                Contact Us
              </Typography>
            </ListItemText>
            <ListItemText>
              <Typography
                lineHeight={2}
                variant="caption2"
                onClick={handleOpenTermsModal}
                style={{ cursor: "pointer" }}
              >
                Privacy &amp; Policy
              </Typography>
            </ListItemText>
            <ListItemText>
              <Typography
                lineHeight={2}
                variant="caption2"
                onClick={handleOpenTermsModal}
                style={{ cursor: "pointer" }}
              >
                Terms &amp; Conditions
              </Typography>
            </ListItemText>
          </List>
        </Grid>
      </Grid>
      <Box sx={{ mt: 4 }}>
        <Typography variant="caption" color={Colors.dove_gray}>
          © COPYRIGHT 2024, LEARNGAADI DRIVING SCHOOL., ALL RIGHTS RESERVED.
        </Typography>
      </Box>
      <Termsmodal open={openTermsModal} handleClose={handleCloseTermsModal} />
    </Box>
  );
}
