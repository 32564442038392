import React, { useState, useEffect } from "react";
import { Paper, Typography, Button, Modal } from "@mui/material";
import { db } from "../../Authentication/firbase.config";
import { collection, getDocs, deleteDoc, doc, updateDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const styles = {
  paper: {
    borderRadius: 15,
    width: "98%",
    margin: "1% auto",
    padding: 20,
    position: "relative",
  },
  resolvedPaper: {
    backgroundColor: "#e6ffe6", // Light green background when resolved
    borderRadius: 15,
    width: "98%",
    margin: "1% auto",
    padding: 20,
    position: "relative",
  },
  inputContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: 16,
  },
  inputLabel: {
    marginRight: 16,
    minWidth: "80px",
    fontWeight: "bold",
  },
  buttonContainer: {
    marginTop: 16,
    display: "flex",
    justifyContent: "space-around",
  },
  button: {
    minWidth: "20%",
  },
  container: {
    display: "flex",
  },
  label: {
    fontWeight: "bold",
  },
  content: {
    marginLeft: "5px",
  },
  successModal: {
    position: "absolute",
    width: 400,
    backgroundColor: "white",
    border: "2px solid #000",
    boxShadow: 24,
    padding: 16,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
};

const Requests = () => {
  const [requests, setRequests] = useState([]);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "requests"));
      const requestData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setRequests(requestData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const openSuccessModal = () => {
    setIsSuccessModalOpen(true);
  };
  
  const closeSuccessModal = () => {
    setIsSuccessModalOpen(false);
  };
  

  const handleDeleteRequest = async (id) => {
    try {
      // Delete the document from the "requests" collection in Firestore
      await deleteDoc(doc(db, "requests", id));

      // Fetch the updated data after deletion
      fetchData();
       // Open the success modal
    openSuccessModal();
    } catch (error) {
      console.error("Error deleting document:", error);
    }
  };

  
  const handleResolveIssue = async (id) => {
    try {
      await updateDoc(doc(db, "requests", id), {
        status: "resolved" // Update status field to resolved
      });
      fetchData();
    } catch (error) {
      console.error("Error resolving issue:", error);
    }
  };

  const handleAddSlot = () => {
    navigate("/home/schedules");
  };

  return (
    <div>
      {requests.map((request, index) => (
   <Paper key={index} elevation={3} style={request.status === "resolved" ? styles.resolvedPaper : styles.paper}>
          <>
          {request.status === "resolved" && (
               <div style={{ textAlign: "right", marginBottom: "10px" }}>
               <CheckCircleIcon style={{ color: "green", marginRight: "5px" }} /> Issue Resolved
             </div>
            )}
            <div style={styles.container}>
              <Typography style={styles.label}>UUID:-</Typography>
              <Typography style={styles.content}>{request.UserId}</Typography>
            </div>
            <div style={styles.container}>
              <Typography style={styles.label}>PhoneNumber:- </Typography>
              <Typography style={styles.content}>
                {request.PhoneNumber}
              </Typography>
            </div>
            <div style={styles.container}>
              <Typography style={styles.label}>Category:-</Typography>
              <Typography style={styles.content}>{request.Category}</Typography>
            </div>
            <div style={styles.container}>
              <Typography style={styles.label}>Subcategory:-</Typography>
              <Typography style={styles.content}>
                {request.Subcategory}
              </Typography>
            </div>
            <div style={styles.container}>
              <Typography style={styles.label}>FromDate:-</Typography>
              <Typography style={styles.content}>{request.fromDate}</Typography>
            </div>
            <div style={styles.container}>
              <Typography style={styles.label}>ToDate</Typography>
              <Typography style={styles.content}>{request.toDate}</Typography>
            </div>
            <div style={styles.container}>
              <Typography style={styles.label}>TimeSlot:-</Typography>
              <Typography style={styles.content}>{request.timeSlot}</Typography>
            </div>
            <div style={styles.container}>
              <Typography style={styles.label}>Location:-</Typography>
              <Typography style={styles.content}>{request.location}</Typography>
            </div>
          </>
          <div style={styles.buttonContainer}>
            {/* <Button
              variant="contained"
              style={{ ...styles.button, backgroundColor: "green" }}
              onClick={handleAddSlot}
            >
              Add a Slot
            </Button> */}
            {request.status !== "resolved" && (
              <Button
                variant="contained"
                style={{
                  ...styles.button,
                  backgroundColor: "orange",
                  color: "black",
                }}
                onClick={() => handleResolveIssue(request.id)}
              >
                Issue Resolved
              </Button>
            )}
            <Button
              variant="contained"
              style={{ ...styles.button, backgroundColor: "red" }}
              onClick={() => handleDeleteRequest(request.id)}
            >
              Delete Request
            </Button>
          </div>
        </Paper>
      ))}
      <Modal open={isSuccessModalOpen} onClose={closeSuccessModal}>
        <div className="modal-container">
          <h2>Status</h2>
          <p>Request Deleted Successfully</p>
          <Button onClick={closeSuccessModal}>OK</Button>
        </div>
      </Modal>
    </div>
  );
};

export default Requests;
