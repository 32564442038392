/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Container,
  Paper,
  Typography,
  Grid,
  Divider,
} from "@mui/material";
import { db } from "../../../Authentication/firbase.config";
import { doc, getDoc } from "firebase/firestore";

const ViewheavytrainerDetails = () => {
  const { id } = useParams();
  const [heavyVehicleDetails, setHeavyVehicleDetails] = useState(null);

  useEffect(() => {
    const fetchHeavyVehicleDetails = async () => {
      try {
        const heavyVehicleDocRef = doc(db, "heavyvehicles", id);
        const heavyVehicleDocSnap = await getDoc(heavyVehicleDocRef);

        if (heavyVehicleDocSnap.exists()) {
          setHeavyVehicleDetails(heavyVehicleDocSnap.data());
        } else {
          console.error("Heavy vehicle not found");
        }
      } catch (error) {
        console.error("Error fetching heavy vehicle details:", error);
      }
    };

    fetchHeavyVehicleDetails();
  }, [id]);

  if (!heavyVehicleDetails) {
    return null;
  }

  return (
    <Container>
      <Paper elevation={3} sx={{ padding: 2, margin: 2 }}>
        <Typography variant="h4" gutterBottom>
          Heavy Vehicle Details
        </Typography>
        <Divider />
        <Grid container spacing={2} marginTop={2} sx={{ padding: 2 }}>
          <Grid item xs={12} md={6}>
            <Typography variant="h5" sx={{ marginBottom: 1 }}>
              Vehicle Image
            </Typography>
            <img
              src={heavyVehicleDetails.vehicleImage}
              alt="Heavy Vehicle"
              style={{ width: "200px", borderRadius: "8px" }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h5" sx={{ marginBottom: 1 }}>
              Driver Image
            </Typography>
            <img
              src={heavyVehicleDetails.DriverImage}
              alt="Driver"
              style={{ maxWidth: "100%", borderRadius: "8px" }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ display: "block", padding: 1 }}>
              <span style={{ fontWeight: "bold" }}>Driver Name:</span>{" "}
              {heavyVehicleDetails.DriverName}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ display: "block", padding: 1 }}>
              <span style={{ fontWeight: "bold" }}>Phone Number:</span>{" "}
              {heavyVehicleDetails.PhoneNumber}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ display: "block", padding: 1 }}>
              <span style={{ fontWeight: "bold" }}>Vehicle Name:</span>{" "}
              {heavyVehicleDetails.VehicleName}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ display: "block", padding: 1 }}>
              <span style={{ fontWeight: "bold" }}>Vehicle Category:</span>{" "}
              {heavyVehicleDetails.VehicleCategory}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ display: "block", padding: 1 }}>
              <span style={{ fontWeight: "bold" }}>Vehicle Number:</span>{" "}
              {heavyVehicleDetails.VehicleNumber}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ display: "block", padding: 1 }}>
              <span style={{ fontWeight: "bold" }}>Location:</span>{" "}
              {heavyVehicleDetails.Location}
            </Typography>
          </Grid>
          {/* Add other details as needed */}
        </Grid>
      </Paper>
    </Container>
  );
};

export default ViewheavytrainerDetails;
