import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import "./login.css";
import { useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../Authentication/firbase.config";
import { Modal, Button } from "@mui/material";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import Logo from "../Images/Logo.png";

function Login() {
  const navigation = useNavigate();
  const [isSignUpMode, setIsSignUpMode] = useState(false);
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [errorAlert, setErrorAlert] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSignUpClick = () => {
    setIsSignUpMode(true);
  };

  const handleSignInClick = () => {
    setIsSignUpMode(false);
  };

  const handleOpenModal = (errorMessage) => {
    // Set the error message in the state
    setErrorAlert(errorMessage);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const SigninUser = async (e) => {
    e.preventDefault();
    setEmailError(false);
    setPasswordError(false);

    // Check if email and password are filled
    if (!email || !password) {
      // Set error states and return early
      setEmailError(!email);
      setPasswordError(!password);
      return;
    }
    try {

      setIsLoading(true);

      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      const app = getFirestore();
      const userDocRef = doc(app, "users", user.uid);
      const userDoc = await getDoc(userDocRef);
      const userData = userDoc.data();

      // Check if the user has 'uid' and 'role' properties
      if (userData && userData.useruid && userData.role) {
        // Check the condition based on the 'role'
        if (userData.role === "admin") {
          navigation("/home");
        } else {
          console.log("User is not an admin");
          const errorMessage = "User is not an admin";
          setErrorAlert(true);
          handleOpenModal(errorMessage);
        }
      } else {
        // console.log('User document does not exist in Firestore');
        const errorMessage = "User is not an admin";
        setErrorAlert(true);
        handleOpenModal(errorMessage);
      }
    } catch (error) {
      console.error(error);
      const errorMessage = "Invalid email or password. Please try again.";
      setErrorAlert(true);
      handleOpenModal(errorMessage);
    } finally {
      // Reset loading state to false after authentication attempt
      setIsLoading(false);
    }
  };

  return (
    <div className={`loginContainer ${isSignUpMode ? "sign-up-mode" : ""}`}>
      <div className="forms-container">
        <div className="signin-signup">
          <form action="#" className="sign-in-form loginForm">
            <h2 className="title">Sign in</h2>
            <div className={`input-field ${emailError ? 'error' : ''}`}>
              <FontAwesomeIcon icon={faEnvelope} className="my-auto mx-auto" />
              <input
                className="LoginInput"
                type="email"
                value={email}
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className={`input-field ${passwordError ? 'error' : ''}`}>
              <FontAwesomeIcon icon={faLock} className="my-auto mx-auto" />
              <input
                className="LoginInput"
                type="password"
                value={password}
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <button onClick={SigninUser} type="submit" className={`btn ${isLoading ? "disabled" : ""}`}
              disabled={isLoading}>
              {isLoading ? "Signing In..." : "Sign In"}
            </button>
            <div className="social-media"></div>
          </form>
          <form action="#" className="sign-up-form loginForm">
            <h2 className="title">Sign up</h2>
            <div className="input-field">
              <FontAwesomeIcon icon={faEnvelope} className="my-auto mx-auto" />
              <input className="LoginInput" type="email" placeholder="Email" />
            </div>
            <div className="input-field">
              <FontAwesomeIcon icon={faLock} className="my-auto mx-auto" />
              <input
                className="LoginInput"
                type="password"
                placeholder="Password"
              />
            </div>
            <button className="btn">Sign Up</button>
            <div className="social-media"></div>
          </form>
        </div>
      </div>
      <div className="panels-container">
        <div className="panel left-panel">
          <div className="content">
            <img src={Logo} alt="Logo" className="logo-image" />
            <h3 className="loginh3">Learngaadi</h3>
            <p className="loginp">
              Patience is something you admire in the driver behind you and
              scorn in the one ahead.
            </p>
            <button className="btn transparent" onClick={handleSignUpClick}>
              Sign up
            </button>
          </div>
          <img src="/img/dogLogin1.svg" className="image" alt="" />
        </div>
        <div className="panel right-panel">
          <div className="content">
            <img src={Logo} alt="Logo" className="logo-image" />
            <h3 className="loginh3">Learngaadi</h3>
            <p className="loginp">
              Patience is something you admire in the driver behind you and
              scorn in the one ahead.
            </p>
            <button
              onClick={handleSignInClick}
              className="btn transparent"
              id="sign-in-btn"
            >
              Sign in
            </button>
          </div>
          <img src="/img/dogLogin.svg" className="image" alt="" />
          <Modal open={openModal} onClose={handleCloseModal}>
            <div className="modal-container">
              <h2>Sign-in Error</h2>
              <p>{errorAlert}</p>
              <Button onClick={handleCloseModal}>OK</Button>
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default Login;
