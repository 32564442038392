import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import "./home.scss";
import Dashboardbg from "../../../Images/dashboard-bg.jpg";
import { Outlet, useOutlet } from "react-router-dom";

const Home = () => {
  const outlet = useOutlet();

  return (
    <div className="home">
      <Sidebar />
      <div className="homeContainer">
        <Navbar />
        {outlet === null && (
          <div className="oulet">
            <div className="customImageContainer">
              <img
                src={Dashboardbg} // Replace with the URL or path of your image
                alt="Dashboard view"
                className="customImage"
              />
            </div>
          </div>
        )}
        <Outlet />
      </div>
    </div>
  );
};

export default Home;
