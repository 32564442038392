import {
  Divider,
  Drawer,
  List,
  ListItemButton,
  ListItemText,
  styled,
} from "@mui/material";
import { useUIContext } from "../../context/ui";
import CloseIcon from "@mui/icons-material/Close";
import { DrawerCloseButton } from "../../styles/appbar";
import { lighten } from "polished";
import { Colors } from "../../styles/theme";
import { useNavigate } from "react-router-dom";

const MiddleDivider = styled((props) => (
  <Divider variant="middle" {...props} />
))``;

export default function AppDrawer() {
  const navigate = useNavigate();
  const { drawerOpen, setDrawerOpen } = useUIContext();

  const handleHome = async () => {
    navigate("/"); // Navigate to the Home page
  };
  const handleAboutus = async () => {
    navigate("/aboutus"); // Navigate to the Aboutus page
  };

  const handleServices = async () => {
    navigate("/services"); // Navigate to the Services page
  };

  const handleContact = async () => {
    navigate("/contactus"); // Navigate to the ContactUs page
  };

  return (
    <>
      {drawerOpen && (
        <DrawerCloseButton onClick={() => setDrawerOpen(false)}>
          <CloseIcon
            sx={{
              fontSize: "2.5rem",
              color: lighten(0.09, Colors.secondary),
            }}
          />
        </DrawerCloseButton>
      )}
      <Drawer open={drawerOpen}>
        <List>
          <ListItemButton>
            <ListItemText onClick={handleHome} style={{ cursor: "pointer" }}>
              Home
            </ListItemText>
          </ListItemButton>
          <MiddleDivider />
          <ListItemButton>
            <ListItemText
              onClick={handleServices}
              style={{ cursor: "pointer" }}
            >
              Services
            </ListItemText>
          </ListItemButton>
          <MiddleDivider />
          <ListItemButton>
            <ListItemText onClick={handleAboutus} style={{ cursor: "pointer" }}>
              About Us
            </ListItemText>
          </ListItemButton>
          <MiddleDivider />
          <ListItemButton>
            <ListItemText onClick={handleContact} style={{ cursor: "pointer" }}>
              Contact Us
            </ListItemText>
          </ListItemButton>
          <MiddleDivider />
        </List>
      </Drawer>
    </>
  );
}
