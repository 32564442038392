import React, { useState, useEffect } from "react";
import {
  Typography,
  Paper,
  FormControl,
  Select,
  MenuItem,
  Grid,
  TextField,
  Button,
  Modal,
} from "@mui/material";
import Chip from "@mui/material/Chip";

import {
  collection,
  getDocs,
  doc,
  getDoc,
  query,
  where,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../Authentication/firbase.config";

const Updateschedule = () => {
  const [drivers, setDrivers] = useState([]);
  const [selectedDriver, setSelectedDriver] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [selectedTimeSlot, setSelectedTimeSlot] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [dateRange, setDateRange] = useState([]);
  const [errorAlert, setErrorAlert] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const timeSlots = [
    "06:00 AM - 07:00 AM",
    "07:00 AM - 08:00 AM",
    "08:00 AM - 09:00 AM",
    "09:00 AM - 10:00 AM",
    "10:00 AM - 11:00 AM",
    "11:00 AM - 12:00 PM",
    "12:00 PM - 01:00 PM",
    "02:00 PM - 03:00 PM",
    "03:00 PM - 04:00 PM",
    "04:00 PM - 05:00 PM",
    "05:00 PM - 06:00 PM",
  ];
  const allTimeSlotsValue = "All Time Slots";

  const handleDriverSelect = (slot) => {
    setSelectedDriver(slot);
  };

  useEffect(() => {
    const fetchDrivers = async () => {
      const bikesSnapshot = await getDocs(collection(db, "bikes"));
      const carsSnapshot = await getDocs(collection(db, "cars"));
      const heavyVehiclesSnapshot = await getDocs(
        collection(db, "heavyvehicles")
      );

      const bikesDrivers = bikesSnapshot.docs.map(
        (doc) => doc.data().DriverName
      );
      const carsDrivers = carsSnapshot.docs.map((doc) => doc.data().DriverName);
      const heavyVehiclesDrivers = heavyVehiclesSnapshot.docs.map(
        (doc) => doc.data().DriverName
      );

      const allDrivers = [
        ...bikesDrivers,
        ...carsDrivers,
        ...heavyVehiclesDrivers,
      ];
      setDrivers(allDrivers);
    };

    fetchDrivers();
  }, []);

  const handleTimeSlotChange = (event) => {
    const selectedValue = event.target.value;

    // Check if "All Time Slots" is selected
    if (selectedValue.includes(allTimeSlotsValue)) {
      setSelectedTimeSlot(timeSlots);
    } else {
      // Toggle the selected value in the array
      const updatedTimeSlots = selectedTimeSlot.includes(selectedValue)
        ? selectedTimeSlot.filter((slot) => slot !== selectedValue)
        : [...selectedTimeSlot, selectedValue];

      // Remove "All Time Slots" if it's present
      const filteredTimeSlots = updatedTimeSlots.includes(allTimeSlotsValue)
        ? timeSlots
        : updatedTimeSlots;

      // Reorder the array based on the order of selection
      const reorderedTimeSlots = timeSlots.filter((slot) =>
        filteredTimeSlots.includes(slot)
      );

      setSelectedTimeSlot(reorderedTimeSlots);
    }
  };

  const handlestatus = (slot) => {
    setSelectedStatus(slot);
  };

  const handleFromDateChange = (event) => {
    setFromDate(event.target.value);
  };

  const handleToDateChange = (event) => {
    setToDate(event.target.value);
  };

  const handleOpenModal = (errorMessage) => {
    // Set the error message in the state
    setErrorAlert(errorMessage);
    setOpenModal(true);
    setIsSubmitting(false);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setErrorAlert(false);
    // Reset the form after closing the modal
  };

  // Function to generate date range between from date and to date
  const generateDateRange = (startDate, endDate) => {
    const dates = [];
    const currentDate = new Date(startDate);
    const endDateObj = new Date(endDate);

    while (currentDate <= endDateObj) {
      const day = String(currentDate.getDate()).padStart(2, "0");
      const month = String(currentDate.getMonth() + 1).padStart(2, "0");
      const year = currentDate.getFullYear();
      dates.push(`${day}-${month}-${year}`);
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dates;
  };

  const updateSchedules = async () => {
    if (!selectedDriver) {
      const errorMessage = "Please select a driver.";
      setErrorAlert(true);
      handleOpenModal(errorMessage);
      return;
    }

    // Check if fromDate and toDate are empty
    if (!fromDate || !toDate) {
      const errorMessage = "Please select both From Date and To Date.";
      setErrorAlert(true);
      handleOpenModal(errorMessage);
      return;
    }

    if (!selectedTimeSlot.length) {
      const errorMessage = "Please select time slots.";
      setErrorAlert(true);
      handleOpenModal(errorMessage);
      return;
    }

    if (!selectedStatus) {
      const errorMessage = "Please select a status.";
      setErrorAlert(true);
      handleOpenModal(errorMessage);
      return;
    }

    try {
      setIsSubmitting(true);

      let collectionName = "";
      let driverQuerySnapshot;

      driverQuerySnapshot = await getDocs(
        query(
          collection(db, "bikes"),
          where("DriverName", "==", selectedDriver)
        )
      );
      if (!driverQuerySnapshot.empty) {
        collectionName = "bikes";
      }

      if (!collectionName) {
        driverQuerySnapshot = await getDocs(
          query(
            collection(db, "cars"),
            where("DriverName", "==", selectedDriver)
          )
        );
        if (!driverQuerySnapshot.empty) {
          collectionName = "cars";
        }
      }

      if (!collectionName) {
        driverQuerySnapshot = await getDocs(
          query(
            collection(db, "heavyvehicles"),
            where("DriverName", "==", selectedDriver)
          )
        );
        if (!driverQuerySnapshot.empty) {
          collectionName = "heavyvehicles";
        }
      }

      if (!collectionName) {
        console.log("Driver not found in any collection.");
        return;
      }

      const dateUpdatePromises = dateRange.map(async (date) => {
        const driverDocs = await getDocs(
          query(
            collection(db, collectionName),
            where("DriverName", "==", selectedDriver)
          )
        );

        if (!driverDocs.empty) {
          const driverDoc = driverDocs.docs[0];
          const scheduleRef = doc(
            db,
            collectionName,
            driverDoc.id,
            "schedule",
            date
          );
          const dateDocSnapshot = await getDoc(scheduleRef);

          if (dateDocSnapshot.exists()) {
            const timeSlotsArray = dateDocSnapshot.data().timeSlots;

            selectedTimeSlot.forEach(async (slot) => {
              const index = timeSlotsArray.findIndex(
                (timeSlot) => timeSlot.timeSlot === slot
              );

              if (index !== -1) {
                timeSlotsArray[index].status = selectedStatus;

                await updateDoc(scheduleRef, { timeSlots: timeSlotsArray });
              } else {
                const errorMessage =
                  "Selected time slot not found in the array in the Database!";
                setErrorAlert(true);
                handleOpenModal(errorMessage);
              }
            });
          } else {
            const errorMessage =
              "Date document does not exist in the Database!";
            setErrorAlert(true);
            handleOpenModal(errorMessage);
          }
        } else {
          const errorMessage = "Driver document not found in the Database!";
          setErrorAlert(true);
          handleOpenModal(errorMessage);
        }
      });

      await Promise.all(dateUpdatePromises);
      const errorMessage = "Status updated successfully in the Database!";
      setErrorAlert(true);
      handleOpenModal(errorMessage);
    } catch (error) {
      const errorMessage = "Error updating schedules in the Database!";
      setErrorAlert(true);
      handleOpenModal(errorMessage);
    } finally {
      // Reset isSubmitting state after submission (whether successful or with an error)
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (fromDate && toDate) {
      const generatedDateRange = generateDateRange(fromDate, toDate);
      setDateRange(generatedDateRange);
      console.log("Generated Date Range:", generatedDateRange);
    }
  }, [fromDate, toDate]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper
          elevation={3}
          sx={{ padding: 2, marginTop: 2, marginLeft: 1, marginRight: 1 }}
        >
          <Typography variant="h5" gutterBottom>
            Update Schedules
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={12} sx={{ margin: "10px" }}>
        <Typography variant="h6">Select Driver:</Typography>
        <FormControl fullWidth sx={{ width: "100%" }}>
          <Select
            value={selectedDriver}
            onChange={(e) => handleDriverSelect(e.target.value)}
          >
            <MenuItem value="">Select Driver</MenuItem>
            {drivers.map((driver, index) => (
              <MenuItem key={index} value={driver}>
                {driver}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Grid container item xs={12} spacing={2} sx={{ marginTop: "2px" }}>
          <Grid item xs={6}>
            <TextField
              id="from-date"
              label="From Date"
              type="date"
              value={fromDate}
              onChange={handleFromDateChange}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="to-date"
              label="To Date"
              type="date"
              value={toDate}
              onChange={handleToDateChange}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
        <Typography variant="h6">Select Time Slot:</Typography>
        <Grid item xs={12}>
          <TextField
            label="Time Slot"
            fullWidth
            select
            multiple
            value={selectedTimeSlot}
            onChange={handleTimeSlotChange}
            InputLabelProps={{ style: { paddingBottom: "8px" } }}
            SelectProps={{
              MenuProps: {
                PaperProps: {
                  style: {
                    maxHeight: 280, // Set your desired max height
                  },
                },
              },
              renderValue: (selected) => {
                const selectedArray = Array.isArray(selected)
                  ? selected
                  : [selected];

                return (
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {selectedArray.includes(allTimeSlotsValue) ? (
                      <Chip
                        key={allTimeSlotsValue}
                        label={allTimeSlotsValue}
                        style={{ margin: 2 }}
                      />
                    ) : (
                      selectedArray.map((value) => (
                        <Chip key={value} label={value} style={{ margin: 2 }} />
                      ))
                    )}
                  </div>
                );
              },
            }}
          >
            <MenuItem value={allTimeSlotsValue}>{allTimeSlotsValue}</MenuItem>
            {timeSlots.map((slot) => (
              <MenuItem key={slot} value={slot}>
                {slot}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Typography variant="h6">Select Status:</Typography>
        <FormControl fullWidth sx={{ width: "100%" }}>
          <Select
            value={selectedStatus}
            onChange={(e) => handlestatus(e.target.value)}
          >
            <MenuItem value="available">available</MenuItem>
            <MenuItem value="unavailable">unavailable</MenuItem>
          </Select>
        </FormControl>
        <Grid
          item
          xs={12}
          sx={{
            textAlign: "center",
            justifyContent: "center",
            marginTop: "10px",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={updateSchedules}
            disabled={isSubmitting}
          >
            Update Schedules
          </Button>
        </Grid>
      </Grid>
      <Modal open={openModal} onClose={handleCloseModal}>
        <div className="modal-container">
          <h2>Status</h2>
          <p>{errorAlert}</p>
          <Button onClick={handleCloseModal}>OK</Button>
        </div>
      </Modal>
    </Grid>
  );
};

export default Updateschedule;
