import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
} from "@mui/material";

const Termsmodal = ({ open, handleClose }) => {
  return (
    <Dialog open={open} onClose={handleClose} >
      <DialogTitle style={{ fontSize: "2rem" }}>
        Terms &amp; Conditions &amp; Privacy Policy
      </DialogTitle>
      <DialogContent>
        {/* Add your Terms & Conditions content here */}
        <Typography variant="body2">
          {/* <Typography variant="h4">Privacy Policy</Typography> */}
          <Typography variant="h6" style={{ marginBottom: 10 }}>
            LearnGaadi Statement of Privacy
          </Typography>
          At LearnGaadi we take your privacy seriously. Please read the
          following to learn more about our terms and conditions.
          <Typography variant="h6" style={{ marginBottom: 10, marginTop: 10 }}>
            What the terms and conditions cover
          </Typography>
          This covers LearnGaadi's treatment of personally identifiable
          information that LearnGaadi collects when you are on LearnGaadi
          Website/App and when you use our services. This policy also covers
          LearnGaadi's treatment of any Personally identifiable information that
          LearnGaadi shares with you. This policy does not apply to the
          practices of companies that LearnGaadi does not own or control or
          LearnGaadi does not own or employ or manage.
          <Typography variant="h6" style={{ marginBottom: 10, marginTop: 10 }}>
            Information Collection and Use
          </Typography>
          LearnGaadi collects personally identifiable information when you
          register for a LearnGaadi account. When you choose the services and
          promotions. LearnGaadi may also receive personally identifiable
          information from our business partners. When you register with
          LearnGaadi, we ask for your name, e-mail address, birth date, gender,
          occupation, industry and personal interest. Once you register with
          LearnGaadi and sign in to our services, you are not anonymous to us.
          LearnGaadi uses information for three general purpose: to fulfill your
          requests for certain products and services and to contact you about
          specials and new products.
          <Typography variant="h6" style={{ marginBottom: 10, marginTop: 10 }}>
            Information Sharing and Disclosure
          </Typography>
          LearnGaadi will not sell or rent your Personally Identifiable
          Information to anyone. LearnGaadi will send Personally Identifiable
          Information about you when: We have consent to share the information
          We need to share your information to provide the product or service
          you have requested We respond to subpoenas, court orders or legal
          process. When we find your action on the web site violates the
          LearnGaadi terms and condition or any of your usage guidelines for
          specific products or services.
          <Typography variant="h6" style={{ marginBottom: 10, marginTop: 10 }}>
            Security
          </Typography>
          Your LearnGaadi account information is password-protected for your
          privacy and security We have taken adequate measures to secure access
          to your personal data?
          <Typography variant="h6" style={{ marginBottom: 10, marginTop: 10 }}>
            Changes to this Policy
          </Typography>
          LearnGaadi may edit this policy from time to time. If we make any
          substantial changes ,we will notify you by posting a prominent
          announcement on our pages.
          <Typography variant="h6" style={{ marginBottom: 10, marginTop: 10 }}>
            Email Disclaimer
          </Typography>
          This electronic transmission contains information from
          LearnGaadiTravels India Limited which is confidential and proprietary,
          and is intended for use only by the person named herein. In case, you
          are not the intended recipient, pls. don’t use this information in any
          manner, whatsoever, and immediately return it to Sender. Any views or
          opinions presented in this email are solely those of the author and
          may not necessarily reflect the opinion of LearnGaadi Travels.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default Termsmodal;
