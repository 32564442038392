import React from "react";
import "./heavyvehicletrainer.scss";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { db } from "../../../Authentication/firbase.config";
import { collection, getDocs, deleteDoc, doc } from "firebase/firestore";
import ConfirmationModal from "../ConfirmationModal";
import { useNavigate } from "react-router-dom";

const Heavytrainerslist = () => {
  const [data, setData] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [selectedToDelete, setSelectedToDelete] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const usersCollection = await getDocs(collection(db, "heavyvehicles"));
        const usersData = usersCollection.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setData(usersData);
      } catch (error) {
        console.error("Error fetching data: ", error);
        console.error("Error details:", error.message);
      }
    };

    fetchUserData();
  }, []);

  const handleDelete = async (id) => {
    try {
      // Get a reference to the user document
      const userDocRef = doc(db, "heavyvehicles", id);

      // Get a reference to the "schedule" subcollection
      const scheduleCollectionRef = collection(userDocRef, "schedule");

      // Fetch all documents from the "schedule" subcollection
      const scheduleDocs = await getDocs(scheduleCollectionRef);

      // Delete each document from the "schedule" subcollection
      scheduleDocs.forEach(async (scheduleDoc) => {
        await deleteDoc(doc(scheduleCollectionRef, scheduleDoc.id));
      });

      // Delete the user document from the "heavyvehicles" collection
      await deleteDoc(userDocRef);

      // Update local state
      setData((prevData) => prevData.filter((item) => item.id !== id));

      // Update selected IDs to prevent row selection
      setSelectedIds((prevSelectedIds) =>
        prevSelectedIds.filter((selectedId) => selectedId !== id)
      );
    } catch (error) {
      console.error("Error deleting document: ", error);
    }
  };

  const handleDeleteClick = (id) => {
    setSelectedToDelete(id);
    setConfirmationModalOpen(true);
  };

  const handleConfirmation = async () => {
    if (selectedToDelete) {
      await handleDelete(selectedToDelete);
      setConfirmationModalOpen(false);
    }
  };

  const userColumns = [
    { field: "id", headerName: "ID", width: 200 },
    { field: "DriverName", headerName: "Driver Name", width: 150 },
    { field: "PhoneNumber", headerName: "Phone Number", width: 150 },
    { field: "VehicleName", headerName: "Heavy Vehicle Name", width: 150 },
    {
      field: "VehicleCategory",
      headerName: "Heavy Vehicle Category",
      width: 150,
    },
    { field: "VehicleNumber", headerName: "Vehicle Number", width: 150 },
    { field: "Location", headerName: "Location", width: 150 },
    { field: "Price", headerName: "Price", width: 150 },
    // Add other columns as needed
  ];

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 350,
      renderCell: (params) => {
        const isSelected = selectedIds.includes(params.row.id);
        return (
          <div className={`cellAction ${isSelected ? "selected" : ""}`}>
            <div
              className="viewButton"
              onClick={() =>
                navigate(`/home/viewheavytrainer/${params.row.id}`)
              }
            >
              View
            </div>
            <div
              className="SchedulesButton"
              onClick={() =>
                navigate(`/home/updateheavyvehicleschedule/${params.row.id}`)
              }
            >
              Add Schedules
            </div>
            <div
              className="updateButton"
              onClick={() =>
                navigate(`/home/updateheavytrainer/${params.row.id}`)
              }
            >
              Update
            </div>
            <div
              className="deleteButton"
              onClick={(event) => {
                event.stopPropagation(); // Prevents row selection
                handleDeleteClick(params.row.id);
              }}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];
  return (
    <div className="datatable">
      <div className="datatableTitle">
        <Link to="/home/addheavytrainer" className="link">
          Add New
        </Link>
      </div>
      <DataGrid
        className="datagrid"
        rows={data}
        columns={userColumns.concat(actionColumn)}
        pageSize={9}
        rowsPerPageOptions={[9]}
        checkboxSelection
        onSelectionModelChange={(newSelection) => {
          // Store selected IDs when the selection changes
          setSelectedIds(newSelection);
        }}
      />
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        onClose={() => setConfirmationModalOpen(false)}
        onConfirm={handleConfirmation}
      />
    </div>
  );
};

export default Heavytrainerslist;
