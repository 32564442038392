import { AppbarContainer, AppbarHeader } from "../../styles/appbar";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import Actions from "./actions";
import { IconButton } from "@mui/material";
import { useUIContext } from "../../context/ui";
import Learngaadiicon from "../../../Images/Learngaadiicon.png";
import { useLocation } from "react-router-dom";

export default function AppbarMobile({ matches }) {
  const { setDrawerOpen, setShowSearchBox } = useUIContext();
  const location = useLocation();

  const isServicesPage = location.pathname === "/";
  return (
    <AppbarContainer>
      <IconButton onClick={() => setDrawerOpen(true)}>
        <MenuIcon />
      </IconButton>
      <AppbarHeader>
        <img
          src={Learngaadiicon}
          alt="LearnGaadi Logo"
          style={{
            height: "60px", // Adjust the height as needed
            width: "auto",
            display: "block",
            margin: "auto", // Center the image
            maxWidth: "100%",
          }}
        />
      </AppbarHeader>
      <IconButton onClick={() => setShowSearchBox(true)}>
        <SearchIcon />
      </IconButton>
      {isServicesPage && <Actions matches={matches} />}
    </AppbarContainer>
  );
}
