import { Paper, Typography, Button, TextField, Grid, Avatar, Box, MenuItem, Chip } from "@mui/material";
import { useState } from "react";

const timeSlots = [
  "09:00 AM - 10:00 AM",
  "10:00 AM - 11:00 AM",
  "11:00 AM - 12:00 PM",
  "12:00 PM - 01:00 PM",
  "02:00 PM - 03:00 PM",
  "03:00 PM - 04:00 PM",
  "04:00 PM - 05:00 PM",
  "05:00 PM - 06:00 PM",
];

const Addschedule = () => {
  const [selectedTimeSlots, setSelectedTimeSlots] = useState([]);

  const handleTimeSlotChange = (event) => {
    const selectedValue = event.target.value;

    if (selectedValue.includes("All Time Slots")) {
      setSelectedTimeSlots([...timeSlots]); // Select all time slots in ascending order
    } else {
      const updatedTimeSlots = selectedTimeSlots.includes("All Time Slots")
        ? [selectedValue]
        : selectedTimeSlots.includes(selectedValue)
          ? selectedTimeSlots.filter((slot) => slot !== selectedValue)
          : [...selectedTimeSlots, selectedValue];

      const sortedTimeSlots = updatedTimeSlots.slice().sort((a, b) => {
        const [aStart, aEnd] = a.split(' - ');
        const [bStart, bEnd] = b.split(' - ');
        return aStart.localeCompare(bStart) || aEnd.localeCompare(bEnd);
      });

      setSelectedTimeSlots(sortedTimeSlots);
    }
  };

  return (
    <Box p={2}>
      <Paper elevation={3} style={{ padding: 16, marginBottom: 16 }}>
        <Typography variant="h6" gutterBottom align='center'>
          Add Slot
        </Typography>
      </Paper>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4} container justifyContent="center" alignItems="center">
          <Avatar alt="Avatar" src="/path/to/avatar-image.jpg" sx={{ width: 150, height: 150 }} />
        </Grid>
        <Grid item xs={12} md={8}>
          <form>
            <TextField fullWidth label="Driver Name" sx={{ marginBottom: 2 }} />
            <TextField fullWidth label="Driver UID" sx={{ marginBottom: 2 }} />
            <TextField fullWidth label="Location" sx={{ marginBottom: 2 }} />
            <TextField fullWidth label="Phone Number" sx={{ marginBottom: 2 }} />
            <TextField fullWidth label="User UID" sx={{ marginBottom: 2 }} />
            <TextField fullWidth label="Vehicle Name" sx={{ marginBottom: 2 }} />
            <TextField fullWidth label="Vehicle Number" sx={{ marginBottom: 2 }} />
            <TextField
              fullWidth
              select
              label="Time Slot"
              value={selectedTimeSlots}
              onChange={handleTimeSlotChange}
              SelectProps={{
                renderValue: (selected) => {
                  const selectedArray = Array.isArray(selected) ? selected : [selected];

                  return (
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                      {selectedArray.includes("All Time Slots") ? (
                        <Chip key="All Time Slots" label="All Time Slots" style={{ margin: 2 }} />
                      ) : (
                        selectedArray.map((value) => (
                          <Chip key={value} label={value} style={{ margin: 2 }} />
                        ))
                      )}
                    </div>
                  );
                },
                MenuProps: {
                  PaperProps: {
                    style: {
                      maxHeight: 200, // Set your desired max height
                    },
                  },
                },
              }}
              multiple
              sx={{ marginBottom: 2 }}
            >
              <MenuItem value="All Time Slots">All Time Slots</MenuItem>
              {timeSlots.map((slot) => (
                <MenuItem key={slot} value={slot}>
                  {slot}
                </MenuItem>
              ))}
            </TextField>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <TextField type="date" margin="normal" style={{ width: '48%' }} />
              <TextField type="date" margin="normal" style={{ width: '48%' }} />
            </div>
            <Button variant="contained" color="primary" style={{ width: '25%', marginLeft: "38%", marginTop: 10 }}>
              Submit
            </Button>
          </form>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Addschedule;



