import React from 'react'
import './maintainance.css'
import Illustration from './img/illustration.svg'

const Maintainance = () => {
  return (
    <>
    <nav className="navbar navbar-expand-lg bg-white">
    <div className="container">
        <a className="navbar-brand" href="#">Learngaadi</a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup"
            aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
        </button>
       
    </div>
</nav>

<section className="content">
    <img src={Illustration} alt="img"/>
    <div className="text">
        <h1>Oops! site under maintenance</h1>
        <p className="w-25">we apologize for any inconveniences caused
            we've almost done.</p>
    </div>
</section>

<footer>
    <div className="container">
        <hr/>
        <p>© 2023 · Learngaadi</p>
    </div>
</footer>
</>
  )
}

export default Maintainance