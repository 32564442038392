import React, { useEffect, useState } from "react";
import {
  Avatar,
  TextField,
  Button,
  Grid,
  MenuItem,
  Typography,
  Paper,
  Modal
} from "@mui/material";
import { useParams } from "react-router-dom";
import { doc, getDoc, updateDoc } from "firebase/firestore"; // Import updateDoc
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { db } from "../../../Authentication/firbase.config";

const Updatecartrainer = () => {
  const { id } = useParams(); // Get the ID from the URL
  const [selectedImage, setSelectedImage] = useState(null); // Add this line
  const [driverImage, setDriverImage] = useState(null);
  const [errorAlert, setErrorAlert] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    DriverName: "",
    PhoneNumber: "",
    VehicleName: "",
    VehicleCategory: "",
    VehicleNumber: "",
    Location: "",
    Price: "",
    vehicleImage: "",
    DriverImage: "",
  });

  useEffect(() => {
    const fetchCarData = async () => {
      try {
        const docRef = doc(db, "cars", id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          setFormData(data);
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching document: ", error);
      }
    };

    fetchCarData();
  }, [id]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(file); // Set the selected image to state
      setFormData((prevData) => ({
        ...prevData,
        vehicleImage: URL.createObjectURL(file),
      }));
    }
  };

  const handleImageButtonClick = () => {
    document.getElementById("imageInput").click();
  };

  const handleDriverImageButtonClick = () => {
    document.getElementById("driverImageInput").click();
  };
  const handleDriverImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setDriverImage(file); // Set the selected image to state
      setFormData((prevData) => ({
        ...prevData,
        DriverImage: URL.createObjectURL(file),
      }));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(`Updating ${name} to: ${value}`);
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const storage = getStorage();

  const handleSubmit = async (e) => {
    setIsSubmitting(true);
    e.preventDefault();

    // Upload images to Firebase Storage and get URLs`````` `
    let vehicleImageUrl = formData.vehicleImage;
    let driverImageUrl = formData.DriverImage;

    if (selectedImage) {
      const vehicleImageRef = ref(storage, `images/${selectedImage.name}`);
      const uploadVehicleTask = uploadBytesResumable(
        vehicleImageRef,
        selectedImage
      );

      await uploadVehicleTask.then((snapshot) => {
        getDownloadURL(snapshot.ref)
          .then((url) => {
            vehicleImageUrl = url;
            console.log("Vehicle Image URL:", vehicleImageUrl); // Debugging

            setFormData((prevData) => ({
              ...prevData,
              vehicleImage: vehicleImageUrl,
            }));
          })
          .catch((error) => {
            console.error("Error getting vehicle image URL:", error);
          });
      });
    }

    if (driverImage) {
      const driverImageRef = ref(storage, `images/${driverImage.name}`);
      const uploadDriverTask = uploadBytesResumable(
        driverImageRef,
        driverImage
      );

      await uploadDriverTask.then((snapshot) => {
        getDownloadURL(snapshot.ref)
          .then((url) => {
            driverImageUrl = url;

            // Update the state with the new driver image URL
            setFormData((prevData) => ({
              ...prevData,
              DriverImage: driverImageUrl,
            }));
          })
          .catch((error) => {
            console.error("Error getting driver image URL:", error);
          });
      });
    }

    try {
      await updateDoc(doc(db, "cars", id), {
        ...formData,
        vehicleImage: vehicleImageUrl,
        DriverImage: driverImageUrl,
      });
      console.log("Car updated successfully!");
       // Display the success alert
       const errorMessage = "Trainer was Updated Successfully in the Database!";
       setErrorAlert(true);
       handleOpenModal(errorMessage);
     } catch (error) {
       console.error("Error saving data:", error);
       const errorMessage = "Trainer was not updated";
       setErrorAlert(true);
       handleOpenModal(errorMessage);
     } finally {
       // Reset isSubmitting state after submission (whether successful or with an error)
       setIsSubmitting(false);
     }
  };

  const carCategories = ["Mini","Small", "Sedan"];
  const Locations = [
    "Rajaji Nagar, 560010",
    "Malleswaram, 560003",
    "Sadashiva Nagar 560080",
    "Seshadripuram, 560020",
    "Gandhi Nagar, 560009",
    "Chickpet, 560053",
    "Magadi Road, 560023",
    "Chamrajpet, 560018",
    "Basavanagudi, 560004",
    "Hanumanthanagar, 560050",
    "City Market, 560002",
    "Vijayanagar, 560040",
    "Kalyananagar, 560043",
    "Kamala Nagar, 560079",
    "Basaveshwaranagar, 560079",
    "Kurubarahalli, 560086",
    "Nandini Layout, 560096",
    "Peenya, 560058",
    "Yeshwanthpur, 560022",
    "Majestic, 560009",
  ];


  const handleOpenModal = (errorMessage) => {
    // Set the error message in the state
    setErrorAlert(errorMessage);
    setOpenModal(true);
    setIsSubmitting(false);
  };

  const handleCloseModal = () => {
    setOpenModal(false);

  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper
          elevation={3}
          sx={{ padding: 2, marginTop: 2, marginLeft: 1, marginRight: 1 }}
        >
          <Typography variant="h5" gutterBottom>
            Car Update Form
          </Typography>
        </Paper>
      </Grid>

      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            paddingLeft: 5,
          }}
        >
          {/* Hidden input for image selection */}
          <input
            type="file"
            accept="image/*"
            id="imageInput"
            style={{ display: "none" }}
            onChange={handleImageChange}
          />
          <Avatar
            alt="Selected Image"
            src={
              formData.vehicleImage
                ? formData.vehicleImage
                : selectedImage
                ? URL.createObjectURL(selectedImage)
                : ""
            }
            sx={{ width: 150, height: 150, marginBottom: 3, marginLeft: 3 }}
          />

          <Button
            variant="contained"
            color="primary"
            onClick={handleImageButtonClick}
          >
            Select Vehicle Image
          </Button>
        </Grid>
        <Grid item xs={6}>
          {/* Hidden input for driver image selection */}
          <input
            type="file"
            accept="image/*"
            id="driverImageInput"
            style={{ display: "none" }}
            onChange={handleDriverImageChange}
          />
          <Avatar
            alt="Selected Driver Image"
            src={
              formData.DriverImage
                ? formData.DriverImage
                : driverImage
                ? URL.createObjectURL(driverImage)
                : ""
            }
            sx={{ width: 150, height: 150, marginBottom: 3, marginLeft: 3 }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleDriverImageButtonClick}
          >
            Select Driver Image
          </Button>
        </Grid>
      </Grid>

      <Grid item xs={6}>
        <TextField
          label="Driver Name"
          fullWidth
          name="DriverName"
          value={formData.DriverName}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="Phone Number"
          fullWidth
          name="PhoneNumber"
          value={formData.PhoneNumber}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="Car Name"
          fullWidth
          name="VehicleName"
          value={formData.VehicleName}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="Car Number"
          fullWidth
          name="VehicleNumber"
          value={formData.VehicleNumber}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="Location"
          fullWidth
          select
          name="Location"
          value={formData.Location}
          onChange={handleChange}
        >
          {Locations.map((location) => (
            <MenuItem key={location} value={location}>
              {location}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="Car Category"
          fullWidth
          select
          name="VehicleCategory"
          value={formData.VehicleCategory}
          onChange={handleChange}
        >
          {carCategories.map((category) => (
            <MenuItem key={category} value={category}>
              {category}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={6}>
        <TextField
          label="Price"
          fullWidth
          name="Price"
          value={formData.Price}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} sx={{ textAlign: "center" }}>
        <Button variant="contained" color="primary" onClick={handleSubmit} disabled={isSubmitting}>
          Update Car
        </Button>
      </Grid>
         {/* Display the Alert when isAlertOpen is true */}
         <Modal open={openModal} onClose={handleCloseModal}>
        <div className="modal-container">
          <h2>Status</h2>
          <p>{errorAlert}</p>
          <Button onClick={handleCloseModal}>OK</Button>
        </div>
      </Modal>
    </Grid>
  );
};

export default Updatecartrainer;
