import { Typography } from "@mui/material";
import {
  BannerContainer,
  BannerContent,
  BannerDescription,
  BannerImage,
  BannerTitle,
} from "../../../styles/banner";

export default function Banner() {
  return (
    <BannerContainer>
      <BannerImage src="/images/banner/Creta.png" sx={{ width: 800 }} />
      <BannerContent>
        <Typography variant="h6">ABOUT SCHOOL</Typography>
        <BannerTitle variant="h2">LearnGaadi</BannerTitle>
        <BannerDescription variant="subtitle">
          Driving schools in Bangalore was founded with a vision to make the
          roads safer. With our well-mannered and experienced instructors, we
          offer a wide range of instructions to our students. We don't believe
          in imparting driving skills but we try to inculcate safe driving among
          our students through intensive theoretical sessions for behavioral
          training and road sense.
        </BannerDescription>

        {/* <BannerShopButton color="primary">Schedule the slot</BannerShopButton> */}
        <ul>
          <li style={{ fontWeight: "bold", marginLeft: "20px" }}>
            Basic driving skills
          </li>
          <li style={{ fontWeight: "bold", marginLeft: "20px" }}>
            Quick Tips and Advice
          </li>
          <li style={{ fontWeight: "bold", marginLeft: "20px" }}>
            Training for Advanced
          </li>
          <li style={{ fontWeight: "bold", marginLeft: "20px" }}>
            Highway and city driving
          </li>
          <li style={{ fontWeight: "bold", marginLeft: "20px" }}>
            Road test preparation
          </li>
        </ul>
      </BannerContent>
    </BannerContainer>
  );
}
