import React, { useState, useEffect } from "react";
import { Paper, Typography } from "@mui/material";
import { db } from "../../Authentication/firbase.config";
import { collection, getDocs } from "firebase/firestore";

const styles = {
  paper: {
    borderRadius: 15,
    width: "98%",
    margin: "1% auto",
    padding: 20,
    position: "relative",
  },
  container: {
    display: "flex",
  },
  label: {
    fontWeight: "bold",
  },
  content: {
    marginLeft: "5px",
  },
};

const Enquery = () => {
  const [enquiryDetails, setEnquiryDetails] = useState([]);
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "enquery"));
        const requestData = querySnapshot.docs.map((doc) => doc.data());
        setEnquiryDetails(requestData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      {enquiryDetails.map((enquiry, index) => (
        <Paper key={index} elevation={3} style={styles.paper}>
          <>
            <div style={styles.container}>
              <Typography style={styles.label}>Name:-</Typography>
              <Typography style={styles.content}>{enquiry.name}</Typography>
            </div>
            <div style={styles.container}>
              <Typography style={styles.label}>Email:-</Typography>
              <Typography style={styles.content}>{enquiry.email}</Typography>
            </div>
            <div style={styles.container}>
              <Typography style={styles.label}>PhoneNumber:-</Typography>
              <Typography style={styles.content}>{enquiry.phone}</Typography>
            </div>
            <div style={styles.container}>
              <Typography style={styles.label}>Message:-</Typography>
              <Typography style={styles.content}>{enquiry.message}</Typography>
            </div>
          </>
        </Paper>
      ))}
    </div>
  );
};

export default Enquery;
