import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  MenuItem,
  Button,
  Modal,
  TextField,
  IconButton,
} from "@mui/material";
import {
  doc,
  collection,
  getDoc,
  setDoc,
} from "firebase/firestore";
import { db } from "../../../Authentication/firbase.config";
import { startOfMonth, endOfMonth, format } from "date-fns";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useParams } from "react-router-dom";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import EventIcon from "@mui/icons-material/Event";
import Chip from "@mui/material/Chip";

const StyledDatePicker = ({ selectedDate, handleDateChange }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ReactDatePicker
        selected={selectedDate}
        onChange={handleDateChange}
        dateFormat="MM/yyyy"
        showMonthYearPicker
        customInput={
          <TextField
            fullWidth
            variant="outlined"
            label="Select Month"
            InputProps={{
              endAdornment: (
                <IconButton
                  onClick={() =>
                    document.getElementById("datePickerInput").click()
                  }
                >
                  <EventIcon />
                </IconButton>
              ),
            }}
          />
        }
        style={{ width: "100%" }}
      />
    </LocalizationProvider>
  );
};
const Updatebikeschedules = () => {
  const { id } = useParams(); // Get the ID from the URL
  const [bikeData, setBikeData] = useState({});
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedTimeSlots, setSelectedTimeSlots] = useState([]);
  const [errorAlert, setErrorAlert] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const timeSlots = [
    "06:00 AM - 07:00 AM",
    "07:00 AM - 08:00 AM",
    "08:00 AM - 09:00 AM",
    "09:00 AM - 10:00 AM",
    "10:00 AM - 11:00 AM",
    "11:00 AM - 12:00 PM",
    "12:00 PM - 01:00 PM",
    "02:00 PM - 03:00 PM",
    "03:00 PM - 04:00 PM",
    "04:00 PM - 05:00 PM",
    "05:00 PM - 06:00 PM",
  ];
  const allTimeSlotsValue = "All Time Slots";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const docRef = doc(db, "bikes", id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setBikeData(docSnap.data());
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching document:", error);
      }
    };

    fetchData();
  }, [id]);

  const handleTimeSlotChange = (event) => {
    const selectedValue = event.target.value;

    // Check if "All Time Slots" is selected
    if (selectedValue.includes(allTimeSlotsValue)) {
      setSelectedTimeSlots(timeSlots);
    } else {
      // Toggle the selected value in the array
      const updatedTimeSlots = selectedTimeSlots.includes(selectedValue)
        ? selectedTimeSlots.filter((slot) => slot !== selectedValue)
        : [...selectedTimeSlots, selectedValue];

      // Remove "All Time Slots" if it's present
      const filteredTimeSlots = updatedTimeSlots.includes(allTimeSlotsValue)
        ? timeSlots
        : updatedTimeSlots;

      // Reorder the array based on the order of selection
      const reorderedTimeSlots = timeSlots.filter((slot) =>
        filteredTimeSlots.includes(slot)
      );

      setSelectedTimeSlots(reorderedTimeSlots);
    }
  };

  const handleAddSchedule = async () => {
    if (!selectedMonth || selectedTimeSlots.length === 0) {
      const errorMessage = "Please select a month and time slots.";
      handleOpenModal(errorMessage);
      return;
    }
  
    try {
      setIsSubmitting(true);
  
      const scheduleRef = collection(db, "bikes", id, "schedule");
  
      const startDate = startOfMonth(selectedMonth);
      const endDate = endOfMonth(selectedMonth);
  
      let currentDate = startDate;
  
      while (currentDate <= endDate) {
        const dateKey = format(currentDate, "dd-MM-yyyy");
        const dateDocumentRef = doc(scheduleRef, dateKey);
  
        const docSnap = await getDoc(dateDocumentRef);
        if (docSnap.exists()) {
          // If schedule already exists for this date, show a message and return
          const errorMessage = `Schedules already exist for ${format(currentDate, "MMMM yyyy")}.`;
          handleOpenModal(errorMessage);
          return;
        } else {
          const timeSlotsData = selectedTimeSlots.map((timeSlot) => ({
            timeSlot,
            status: "available",
          }));
  
          await setDoc(dateDocumentRef, { timeSlots: timeSlotsData });
          console.log("Schedule added for:", dateKey);
        }
  
        // Move to the next day
        currentDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1);
      }
  
      const successMessage = "Schedules added successfully!";
      handleOpenModal(successMessage);
    } catch (error) {
      console.error("Error adding schedules:", error);
      const errorMessage = "Error adding schedules. Please try again.";
      handleOpenModal(errorMessage);
    } finally {
      setIsSubmitting(false);
    }
  };
  

  const handleOpenModal = (errorMessage) => {
    setErrorAlert(errorMessage);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setErrorAlert(false);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom>
          Update Bike Trainer: {bikeData.DriverName}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
          Add Schedules for Another Month
        </Typography>
        <StyledDatePicker
          selectedDate={selectedMonth}
          handleDateChange={setSelectedMonth}
        />
        <TextField
          label="Time Slot"
          fullWidth
          select
          multiple
          value={selectedTimeSlots}
          onChange={handleTimeSlotChange}
          InputLabelProps={{ style: { paddingBottom: "8px" } }}
          SelectProps={{
            MenuProps: {
              PaperProps: {
                style: {
                  maxHeight: 280, // Set your desired max height
                },
              },
            },
            renderValue: (selected) => {
              const selectedArray = Array.isArray(selected)
                ? selected
                : [selected];

              return (
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {selectedArray.includes(allTimeSlotsValue) ? (
                    <Chip
                      key={allTimeSlotsValue}
                      label={allTimeSlotsValue}
                      style={{ margin: 2 }}
                    />
                  ) : (
                    selectedArray.map((value) => (
                      <Chip key={value} label={value} style={{ margin: 2 }} />
                    ))
                  )}
                </div>
              );
            },
          }}
        >
          <MenuItem value={allTimeSlotsValue}>{allTimeSlotsValue}</MenuItem>
          {timeSlots.map((slot) => (
            <MenuItem key={slot} value={slot}>
              {slot}
            </MenuItem>
          ))}
        </TextField>
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddSchedule}
            disabled={isSubmitting}
          >
            Add Schedules
          </Button>
        </Grid>
      </Grid>
      <Modal open={openModal} onClose={handleCloseModal}>
        <div className="modal-container">
          <h2>Status</h2>
          <p>{errorAlert}</p>
          <Button onClick={handleCloseModal}>OK</Button>
        </div>
      </Modal>
    </Grid>
  );
};

export default Updatebikeschedules;
