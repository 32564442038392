import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Grid, Typography, Select, MenuItem, FormControl } from "@mui/material";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../../Authentication/firbase.config";

const Driverreports = () => {
  const [selectedDriver, setSelectedDriver] = useState("");
  const [drivers, setDrivers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [reports, setReports] = useState([]);
  const [uniqueDatesArray, setUniqueDatesArray] = useState([]);

  useEffect(() => {
    const fetchDrivers = async () => {
      const bikesSnapshot = await getDocs(collection(db, "bikes"));
      const carsSnapshot = await getDocs(collection(db, "cars"));
      const heavyVehiclesSnapshot = await getDocs(
        collection(db, "heavyvehicles")
      );

      const bikesDrivers = bikesSnapshot.docs.map(
        (doc) => doc.data().DriverName
      );
      const carsDrivers = carsSnapshot.docs.map((doc) => doc.data().DriverName);
      const heavyVehiclesDrivers = heavyVehiclesSnapshot.docs.map(
        (doc) => doc.data().DriverName
      );

      const allDrivers = [
        ...bikesDrivers,
        ...carsDrivers,
        ...heavyVehiclesDrivers,
      ];
      setDrivers(allDrivers);
      setLoading(false);
    };

    fetchDrivers();
  }, []);

  const timeSlots = [
    "06:00 AM - 07:00 AM",
    "07:00 AM - 08:00 AM",
    "08:00 AM - 09:00 AM",
    "09:00 AM - 10:00 AM",
    "10:00 AM - 11:00 AM",
    "11:00 AM - 12:00 PM",
    "12:00 PM - 01:00 PM",
    "02:00 PM - 03:00 PM",
    "03:00 PM - 04:00 PM",
    "04:00 PM - 05:00 PM",
    "05:00 PM - 06:00 PM",
  ];

  const columns = [
    { field: "Date", headerName: "Date", width: 150 },
    ...timeSlots.map((slot, index) => ({
      field: `timeSlot${index}`,
      headerName: slot,
      width: 330,
    })),
  ];

  const handleDriverSelect = (driverName) => {
    setSelectedDriver(driverName);
  };

  const fetchReports = async () => {
    if (selectedDriver) {
      console.log("Fetching reports for driver:", selectedDriver);
      const driverReports = [];
      const q = query(
        collection(db, "bookings"),
        where("DriverName", "==", selectedDriver)
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        const report = {
          id: doc.id,
          Date: data.dates,
          TimeSlot: data.TimeSlot, // Extracting the TimeSlot from the document
          UserPhoneNumber: data.UserPhoneNumber,
        };
        driverReports.push(report);
      });

      // Gather all unique dates
      const allDates = new Set();
      driverReports.forEach((report) => {
        Object.keys(report.Date).forEach((date) => {
          allDates.add(date);
        });
      });

      // Convert the Set to an array and sort it
      const sortedUniqueDatesArray = Array.from(allDates).sort();
      console.log("Sorted Unique Dates:", sortedUniqueDatesArray);

      setUniqueDatesArray(sortedUniqueDatesArray);

      const processedReports = [];
      sortedUniqueDatesArray.forEach((date) => {
        const rowData = {
          id: date, // Using date as the unique key
          Date: date,
        };
        timeSlots.forEach((slot, index) => {
          let status = "Not Assigned"; // Default status if not assigned
          driverReports.forEach((report) => {
            const timeSlotData = report.Date[date];
            if (timeSlotData && report.TimeSlot === slot) {
              status = `User: ${report.UserPhoneNumber}, Status: ${timeSlotData.status}`;
            }
          });
          rowData[`timeSlot${index}`] = status;
        });
        processedReports.push(rowData);
      });

      console.log("Processed reports:", processedReports);

      setReports(processedReports);
    }
  };

  useEffect(() => {
    fetchReports();
  }, [selectedDriver]);

  if (loading) return <Typography>Loading...</Typography>;

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Typography variant="h6">Select Driver:</Typography>
        <div style={{ maxWidth: 300, overflow: "hidden" }}>
          {" "}
          {/* Adjust maxWidth according to your layout */}
          <FormControl fullWidth>
            <Select
              value={selectedDriver}
              onChange={(e) => handleDriverSelect(e.target.value)}
              MenuProps={{ PaperProps: { style: { maxHeight: 300 } } }} // Limiting max height of the menu
            >
              <MenuItem value="">Select Driver</MenuItem>
              {drivers.map((driver, index) => (
                <MenuItem key={index} value={driver}>
                  {driver}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </Grid>

      <Grid item xs={12}>
        <div style={{ height: 400, width: "100%" }}>
          <DataGrid
            rows={reports}
            columns={columns}
            pageSize={5}
            checkboxSelection
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default Driverreports;
