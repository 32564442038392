import { Container, Typography, Grid, Paper, Stack } from "@mui/material";
import BikeImage from "../../Images/bike1.jpg";
import CarImage from "../../Images/car1.jpg";
import HeavyVehicleImage from "../../Images/bus.1.jpg";
import Appbar from "../components/appbar";
import { ThemeProvider } from "@mui/system";
import theme from "../styles/theme";
import AppDrawer from "../components/drawer";
import SearchBox from "../components/search";

function Services() {

  const servicesData = [
  {
    id: 1,
    title: "Bike Training (With Gear & Without Gear)",
    image: BikeImage,
    subServices: [
      { id: 1, title: "Geared Bike", description: "Description for Geared Bike" },
      { id: 2, title: "Gearless Bike", description: "Description for Gearless Bike" },
    ],
  },
  {
    id: 2,
    title: "Car Training : Sedan/Suv/Hatchback",
    image: CarImage,
    subServices: [
      { id: 1, title: "Sedan", description: "Description for Sedan" },
      { id: 2, title: "SUV", description: "Description for SUV" },
      { id: 3, title: "Hatchback", description: "Description for Hatchback" },
    ],
  },
  {
    id: 3,
    title: "Heavy Vehicle Training : Bus/Trucks",
    image: HeavyVehicleImage,
    subServices: [
      { id: 1, title: "Truck", description: "Description for Truck" },
      { id: 2, title: "Bus", description: "Description for Bus" },
    ],
  },
];

  return (
    <ThemeProvider theme={theme}>
      <Container
        disableGutters
        maxWidth="xl"
        sx={{
          background: "#fff",
        }}
      >
        <Stack>
          <Appbar />
          <Container maxWidth="xl" sx={{ mt: 4, backgroundColor:"#dcf2c9" }}>
          <Typography variant="h4" align="center" sx={{ mb: 4 }}>
            Our Services
          </Typography>
          <Grid container spacing={3}>
            {servicesData.map((service) => (
              <Grid key={service.id} item xs={12} sm={6} md={4}>
                <Paper sx={{ padding: 2, textAlign: "center" }}>
                  <img
                    src={service.image}
                    alt={service.title}
                    style={{ width: "100%", height: "auto" }}
                  />
                  <Typography variant="h6" sx={{ mt: 2 }}>
                    {service.title}
                  </Typography>
                  {/* <List sx={{ mt: 1 }}>
                    {service.subServices.map((subService) => (
                      <ListItem key={subService.id} disablePadding>
                        <ListItemText
                          primaryTypographyProps={{ variant: "body2" }}
                          primary={<strong>{subService.title}:</strong>}
                          secondary={subService.description}
                        />
                      </ListItem>
                    ))}
                  </List> */}
                </Paper>
              </Grid>
            ))}
          </Grid>
          </Container>
          <SearchBox />
          <AppDrawer />
        </Stack>
      </Container>
    </ThemeProvider>
  );
}

export default Services;
