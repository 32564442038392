import { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import {
  useAuth,
  AuthProvider,
} from "../Authentication/Authentication.context";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../Authentication/firbase.config";
import Blog from "../Blog/Blog";
import { UIProvider } from "../Blog/context/ui";
import Banner from "../Blog/components/appbar/banner";
import Services from "../Blog/pages/Services";
import Contactus from "../Blog/pages/Contactus";
import Aboutus from "../Blog/pages/Aboutus";
import Login from "../Login/Login";
import Home from "../Dashboard/pages/Home/Home";
import User from "../Dashboard/pages/Users/User";
import Addbiketrainer from "../Dashboard/pages/BikeTrainers/Addbiketrainer";
import Addcartrainer from "../Dashboard/pages/CarTrainers/Addcartrainer";
import Addheavytrainer from "../Dashboard/pages/HeavyTrainers/Addheavytrainer";
import Biketrainerslist from "../Dashboard/pages/BikeTrainers/Biketrainerslist";
import Cartrainerslist from "../Dashboard/pages/CarTrainers/Cartrainerslist";
import Heavytrainerslist from "../Dashboard/pages/HeavyTrainers/Heavytrainerslist";
import ViewbiketrainerDetails from "../Dashboard/pages/BikeTrainers/ViewbiketrainerDetails";
import ViewcartrainerDetails from "../Dashboard/pages/CarTrainers/ViewcartrainerDetails";
import ViewheavytrainerDetails from "../Dashboard/pages/HeavyTrainers/ViewheavytrainerDetails";
import Updatebiketrainer from "../Dashboard/pages/BikeTrainers/Updatebiketrainer";
import Updatecartrainer from "../Dashboard/pages/CarTrainers/Updatecartrainer";
import Updateheavytrainer from "../Dashboard/pages/HeavyTrainers/Updateheavytrainer";
import Requests from "../Dashboard/pages/Requests";
import Enquery from "../Dashboard/pages/Enquery";
import Addschedule from "../Dashboard/pages/Addschedule";
import Updatebikeschedules from "../Dashboard/pages/BikeTrainers/Updatebikeschedules";
import Updatecarschedules from "../Dashboard/pages/CarTrainers/Updatecarschedules";
import Updateheavyvehicleschedules from "../Dashboard/pages/HeavyTrainers/Updateheavyvehicleschedules";
import Appquery from "../Dashboard/pages/Appquery";
import Driverreports from "../Dashboard/pages/Reports/Driverreports";
import Updateschedule from "../Dashboard/pages/Updateschedule";
import Maintainance from "../Maintainance/Maintainance";

function ProtectedRoute({ element, ...rest }) {
  const auth = useAuth();

  return auth.user ? (
    element
  ) : (
    <Navigate
      to="/"
      replace={true}
      state={{ from: rest?.location?.pathname || "/" }}
    />
  );
}

function generateRoutes(maintenanceStatus) {
  if (maintenanceStatus === "online") {
    return (
      <>
        <Route path="/banner" element={<Banner />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contactus" element={<Contactus />} />
        <Route path="/aboutus" element={<Aboutus />} />
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Blog />} />
        <Route path="/home" element={<ProtectedRoute element={<Home />} />}>
          <Route path="users" element={<ProtectedRoute element={<User />} />} />
          <Route
            path="addbiketrainer"
            element={<ProtectedRoute element={<Addbiketrainer />} />}
          />
          <Route
            path="addcartrainer"
            element={<ProtectedRoute element={<Addcartrainer />} />}
          />
          <Route
            path="addheavytrainer"
            element={<ProtectedRoute element={<Addheavytrainer />} />}
          />
          <Route
            path="biketrainers"
            element={<ProtectedRoute element={<Biketrainerslist />} />}
          />
          <Route
            path="cartrainers"
            element={<ProtectedRoute element={<Cartrainerslist />} />}
          />
          <Route
            path="heavytrainers"
            element={<ProtectedRoute element={<Heavytrainerslist />} />}
          />
          <Route
            path="viewbiketrainer/:id"
            element={<ProtectedRoute element={<ViewbiketrainerDetails />} />}
          />
          <Route
            path="viewcartrainer/:id"
            element={<ProtectedRoute element={<ViewcartrainerDetails />} />}
          />
          <Route
            path="viewheavytrainer/:id"
            element={<ProtectedRoute element={<ViewheavytrainerDetails />} />}
          />
          <Route
            path="updatebiketrainer/:id"
            element={<ProtectedRoute element={<Updatebiketrainer />} />}
          />
          <Route
            path="updatebikeschedule/:id"
            element={<ProtectedRoute element={<Updatebikeschedules />} />}
          />
          <Route
            path="updatecartrainer/:id"
            element={<ProtectedRoute element={<Updatecartrainer />} />}
          />
          <Route
            path="updatecarschedule/:id"
            element={<ProtectedRoute element={<Updatecarschedules />} />}
          />
          <Route
            path="updateheavytrainer/:id"
            element={<ProtectedRoute element={<Updateheavytrainer />} />}
          />
          <Route
            path="updateheavyvehicleschedule/:id"
            element={
              <ProtectedRoute element={<Updateheavyvehicleschedules />} />
            }
          />
          <Route
            path="requests"
            element={<ProtectedRoute element={<Requests />} />}
          />
          <Route
            path="enquiries"
            element={<ProtectedRoute element={<Enquery />} />}
          />
          <Route
            path="schedules"
            element={<ProtectedRoute element={<Addschedule />} />}
          />
          <Route
            path="appquery"
            element={<ProtectedRoute element={<Appquery />} />}
          />
          <Route
            path="driverreports"
            element={<ProtectedRoute element={<Driverreports />} />}
          />
          <Route
            path="updateschedule"
            element={<ProtectedRoute element={<Updateschedule />} />}
          />
        </Route>
      </>
    );
  } else if (maintenanceStatus === "offline") {
    return <Route path="/*" element={<Maintainance />} />;
  }
}

function Rootstack() {
  const [maintenanceStatus, setMaintenanceStatus] = useState(null);

  useEffect(() => {
    const fetchMaintenanceStatus = async () => {
      const q = collection(db, "maintainance");
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        const status = doc.data().web;
        setMaintenanceStatus(status);
      });
    };

    fetchMaintenanceStatus();
  }, []);

  if (maintenanceStatus === null) {
    // Loading state
    return null;
  }

  return (
    <AuthProvider>
      <UIProvider>
        <Router>
          <Routes>{generateRoutes(maintenanceStatus)}</Routes>
        </Router>
      </UIProvider>
    </AuthProvider>
  );
}

export default Rootstack;
