import React, { useState } from "react";
import {
  Avatar,
  TextField,
  MenuItem,
  Button,
  Grid,
  Typography,
  Paper,
  IconButton,
  Modal,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import EventIcon from "@mui/icons-material/Event";
import Chip from "@mui/material/Chip";
import dayjs from "dayjs/esm";
import { collection, setDoc, doc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import { db, storage } from "../../../Authentication/firbase.config";
import { startOfMonth, endOfMonth, format } from "date-fns";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./heavyvehicletrainer.scss";
import Defaultperson from "../../../Images/Defaultperson.png";
import Default_bus from "../../../Images/Defaultheavyvehicle.png";

const StyledDatePicker = ({ selectedDate, handleDateChange }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ReactDatePicker
        selected={selectedDate}
        onChange={handleDateChange}
        dateFormat="MM/yyyy"
        showMonthYearPicker
        customInput={
          <TextField
            fullWidth
            variant="outlined"
            label="Select Date"
            InputProps={{
              endAdornment: (
                <IconButton
                  onClick={() =>
                    document.getElementById("datePickerInput").click()
                  }
                >
                  <EventIcon />
                </IconButton>
              ),
            }}
          />
        }
        style={{ width: "100%" }}
      />
    </LocalizationProvider>
  );
};

const Addheavytrainer = () => {
  const [driverImage, setDriverImage] = useState(Defaultperson);
  const [heavyvehicleNumber, setHeavyVehicleNumber] = useState("");
  const [heavyvehicleName, setHeavyVehicleName] = useState("");
  const [heavyvehicleCategory, setHeavyVehicleCategory] = useState("");
  const [price, setPrice] = useState("");
  const [driverName, setDriverName] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTimeSlots, setSelectedTimeSlots] = useState([]);
  const [selectedImage, setSelectedImage] = useState(Default_bus);
  const [phoneNumber, setphoneNumber] = useState("");
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [Location, setLocation] = useState("");
  const [errorAlert, setErrorAlert] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const heavyvehicleCategories = ["Bus"];
  const timeSlots = [
    "06:00 AM - 07:00 AM",
    "07:00 AM - 08:00 AM",
    "08:00 AM - 09:00 AM",
    "09:00 AM - 10:00 AM",
    "10:00 AM - 11:00 AM",
    "11:00 AM - 12:00 PM",
    "12:00 PM - 01:00 PM",
    "02:00 PM - 03:00 PM",
    "03:00 PM - 04:00 PM",
    "04:00 PM - 05:00 PM",
    "05:00 PM - 06:00 PM",
  ];
  const allTimeSlotsValue = "All Time Slots";
  const Locations = [
    "Rajaji Nagar, 560010",
    "Malleswaram, 560003",
    "Sadashiva Nagar 560080",
    "Seshadripuram, 560020",
    "Gandhi Nagar, 560009",
    "Chickpet, 560053",
    "Magadi Road, 560023",
    "Chamrajpet, 560018",
    "Basavanagudi, 560004",
    "Hanumanthanagar, 560050",
    "City Market, 560002",
    "Vijayanagar, 560040",
    "Kalyananagar, 560043",
    "Kamala Nagar, 560079",
    "Basaveshwaranagar, 560079",
    "Kurubarahalli, 560086",
    "Nandini Layout, 560096",
    "Peenya, 560058",
    "Yeshwanthpur, 560022",
    "Majestic, 560009",
  ];

  const resetForm = () => {
    setHeavyVehicleName("");
    setHeavyVehicleCategory("");
    setPrice("");
    setDriverName("");
    setSelectedDate(null);
    setSelectedTimeSlots([]);
    // setSelectedImage(null); // No need to reset this as it's controlled by the user
    setphoneNumber("");
    setSelectedMonth(null);
    setLocation("");
    // setDriverImage(null); // No need to reset this as it's controlled by the user
    setHeavyVehicleNumber("");
  };

  const handleDateChange = (date) => {
    // Ensure date is not null or undefined
    if (date) {
      // Manually format the date in the desired format (MM/YYYY)
      const formattedDate = dayjs(date).format("MM/YYYY");
      console.log("Selected Date:", formattedDate);

      // Convert the formatted date to a JavaScript Date object
      const dateObject = new Date(date);

      setSelectedDate(dateObject);
      setSelectedMonth(formattedDate);
    }
  };

  const handleTimeSlotChange = (event) => {
    const selectedValue = event.target.value;

    // Check if "All Time Slots" is selected
    if (selectedValue.includes(allTimeSlotsValue)) {
      setSelectedTimeSlots(timeSlots);
    } else {
      // Toggle the selected value in the array
      const updatedTimeSlots = selectedTimeSlots.includes(selectedValue)
        ? selectedTimeSlots.filter((slot) => slot !== selectedValue)
        : [...selectedTimeSlots, selectedValue];

      // Remove "All Time Slots" if it's present
      const filteredTimeSlots = updatedTimeSlots.includes(allTimeSlotsValue)
        ? timeSlots
        : updatedTimeSlots;

      // Reorder the array based on the order of selection
      const reorderedTimeSlots = timeSlots.filter((slot) =>
        filteredTimeSlots.includes(slot)
      );

      setSelectedTimeSlots(reorderedTimeSlots);
    }
  };

  const handleImageChange = (event) => {
    // Handle image selection logic here
    const file = event.target.files[0];
    setSelectedImage(file || Default_bus);
  };

  const handleDriverImageChange = (event) => {
    const file = event.target.files[0];
    setDriverImage(file || Defaultperson);
  };

  const handleImageButtonClick = () => {
    // Trigger the input file click when the button is clicked
    document.getElementById("imageInput").click();
  };

  const handleDriverImageButtonClick = () => {
    document.getElementById("driverImageInput").click();
  };

  const handleOpenModal = (errorMessage) => {
    // Set the error message in the state
    setErrorAlert(errorMessage);
    setOpenModal(true);
    setIsSubmitting(false);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    // Reset the form after closing the modal
    resetForm();
  };


  const handleSubmit = async () => {
    try {
      if (
        !heavyvehicleName ||
        !heavyvehicleCategory ||
        !price ||
        !driverName ||
        !selectedDate ||
        !Location ||
        !phoneNumber ||
        selectedTimeSlots.length === 0 ||
        !selectedImage ||
        !driverImage ||
        !heavyvehicleNumber
      ) {
        const errorMessage = "Please fill in all required fields.";
        handleOpenModal(errorMessage);
        return; // Stop further execution if any required field is missing
      }
      setIsSubmitting(true);


      const imageUrl = await getVehicleImageUrl(
        "heavyvehicles",
        selectedImage,
        Default_bus
      );

      const driverImageUrl = await getDriverImageUrl(
        "heavyvehicles",
        driverImage,
        Defaultperson
      );

      const heavyvehicleData = {
        UID: phoneNumber,
        VehicleName: heavyvehicleName,
        VehicleCategory: heavyvehicleCategory,
        Price: price,
        DriverName: driverName,
        PhoneNumber: phoneNumber,
        Location,
        vehicleImage: imageUrl,
        DriverImage: driverImageUrl,
        VehicleNumber: heavyvehicleNumber,
      };

      const heavyvehicleRef = doc(db, "heavyvehicles", phoneNumber);
      await setDoc(heavyvehicleRef, heavyvehicleData);

      // Create a subcollection reference under 'schedule' using the selectedMonth
      const scheduleRef = collection(heavyvehicleRef, "schedule");

      // Get the first day and last day of the selected month
      const startDate = startOfMonth(selectedDate);
      const endDate = endOfMonth(startDate);

      console.log("Start Date:", startDate);
      console.log("End Date:", endDate);

      // Loop through the days of the month
      for (
        let currentDate = startDate;
        currentDate <= endDate;
        currentDate.setDate(currentDate.getDate() + 1)
      ) {
        // Check if the current day is Saturday or Sunday
        const dayOfWeek = currentDate.getDay();
        if (dayOfWeek === 0 || dayOfWeek === 6) {
          // Skip Saturdays and Sundays
          continue;
        }

        // Format the date as needed for Firestore subcollection
        const formattedDate = format(currentDate, "dd-MM-yyyy");
        console.log("Processing Date:", formattedDate);

        // Create a document reference within the 'schedule' subcollection
        const dateDocumentRef = doc(scheduleRef, formattedDate);

        // Create an array to store time slots in order
        const timeSlotsData = selectedTimeSlots.map((timeSlot) => ({
          timeSlot,
          status: "available",
        }));

        // Use setDoc() to set the data within the document
        await setDoc(dateDocumentRef, {
          timeSlots: timeSlotsData,
        });
      }

      console.log("Data saved successfully!");

      // Display the success alert
      const errorMessage = "Trainer was added Successfully to the Database!";
      setErrorAlert(true);
      handleOpenModal(errorMessage);
    } catch (error) {
      console.error("Error saving data:", error);
      const errorMessage = "Trainer was not added";
      setErrorAlert(true);
      handleOpenModal(errorMessage);
    } finally {
      // Reset isSubmitting state after submission (whether successful or with an error)
      setIsSubmitting(false);
    }
  };

  const uploadDriverImageToStorage = async (folder, image) => {
    try {
      // Use the original name if it's a file
      const imageName = image instanceof File ? image.name : null;
      const storageRef = ref(storage, `${folder}/${imageName}`);
      await uploadBytes(storageRef, image);
      const downloadURL = await getDownloadURL(storageRef);
      return downloadURL;
    } catch (error) {
      console.error("Error uploading image to storage:", error);
      return ""; // Return an empty string in case of an error
    }
  };

  const uploadVehicleImageToStorage = async (folder, image) => {
    try {
      // Use the original name if it's a file
      const imageName = image instanceof File ? image.name : null;
      const storageRef = ref(storage, `${folder}/${imageName}`);
      await uploadBytes(storageRef, image);
      const downloadURL = await getDownloadURL(storageRef);
      return downloadURL;
    } catch (error) {
      console.error("Error uploading image to storage:", error);
      return ""; // Return an empty string in case of an error
    }
  };

  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const getDriverImageUrl = async (folder, image) => {
    if (image instanceof File) {
      // If it's a File, upload to storage and get the URL
      return await uploadDriverImageToStorage(folder, image);
    } else if (typeof image === "string" && image.startsWith("data:image")) {
      // If it's a base64-encoded image, handle it accordingly
      const file = dataURLtoFile(image, "driverImage.jpg");
      return await uploadDriverImageToStorage(folder, file);
    } else {
      // If it's not a File or base64-encoded image, assume it's a default image path
      return image;
    }
  };

  const getVehicleImageUrl = async (folder, image, defaultImage) => {
    if (!image) {
      // If there is no selected image, use the default image
      return defaultImage;
    }

    if (image instanceof File) {
      // If it's a File, upload to storage and get the URL
      return await uploadVehicleImageToStorage(folder, image);
    } else if (typeof image === "string" && image.startsWith("data:image")) {
      // If it's a base64-encoded image, handle it accordingly
      const file = dataURLtoFile(image, "vehicleImage.jpg");
      return await uploadVehicleImageToStorage(folder, file);
    } else {
      // If it's not a File or base64-encoded image, assume it's a default image path
      return image;
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper
          elevation={3}
          sx={{ padding: 2, marginTop: 2, marginLeft: 1, marginRight: 1 }}
        >
          <Typography variant="h5" gutterBottom>
            Heavy Vehicle Rental Form
          </Typography>
        </Paper>
      </Grid>
      {/* Left side: Image selection */}
      <Grid
        item
        xs={12}
        sm={6}
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Grid
          item
          xs={6}
          sx={{
            paddingLeft: 5,
          }}
        >
          {/* Hidden input for image selection */}
          <input
            type="file"
            accept="image/*"
            id="imageInput"
            style={{ display: "none" }}
            onChange={handleImageChange}
          />
          <Avatar
            alt="Selected Image"
            src={
              selectedImage
                ? selectedImage instanceof File
                  ? URL.createObjectURL(selectedImage)
                  : selectedImage
                : Default_bus
            }
            sx={{ width: 150, height: 150, marginBottom: 3, marginLeft: 3 }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleImageButtonClick}
          >
            Select Vehicle Image
          </Button>
        </Grid>
        <Grid item xs={6}>
          {/* Hidden input for driver image selection */}
          <input
            type="file"
            accept="image/*"
            id="driverImageInput"
            style={{ display: "none" }}
            onChange={handleDriverImageChange}
          />
          <Avatar
            alt="Selected Driver Image"
            src={
              driverImage
                ? driverImage instanceof File
                  ? URL.createObjectURL(driverImage)
                  : driverImage
                : null
            }
            sx={{ width: 150, height: 150, marginBottom: 3, marginLeft: 3 }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleDriverImageButtonClick}
          >
            Select Driver Image
          </Button>
        </Grid>
      </Grid>

      {/* Right side: Text fields */}
      <Grid item container xs={12} sm={6} spacing={2} sx={{ paddingRight: 2 }}>
        <Grid item xs={12}>
          <TextField
            label="Driver Name"
            fullWidth
            value={driverName}
            onChange={(e) => setDriverName(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Heavy Vehicle Name"
            fullWidth
            value={heavyvehicleName}
            onChange={(e) => setHeavyVehicleName(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            select
            label="Heavy Vehicle Category"
            fullWidth
            value={heavyvehicleCategory}
            onChange={(e) => setHeavyVehicleCategory(e.target.value)}
          >
            {heavyvehicleCategories.map((category) => (
              <MenuItem key={category} value={category}>
                {category}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Heavy Vehicle Number"
            fullWidth
            value={heavyvehicleNumber}
            onChange={(e) => setHeavyVehicleNumber(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Price"
            fullWidth
            type="number"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
          />
        </Grid>

        <Grid item container xs={12} spacing={2}>
          <Grid item xs={6}>
            <TextField
              label="Phone Number"
              fullWidth
              type="tel" // Set the input type to 'tel' for phone numbers
              value={phoneNumber}
              onChange={(e) => setphoneNumber(e.target.value)}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              select
              label="Location"
              fullWidth
              value={Location}
              onChange={(e) => setLocation(e.target.value)}
            >
              {Locations.map((category) => (
                <MenuItem key={category} value={category}>
                  {category}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <StyledDatePicker
            selectedDate={selectedDate}
            handleDateChange={handleDateChange}
          />
          {/* Hidden input for triggering date picker */}
          <input id="datePickerInput" style={{ display: "none" }} />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Time Slot"
            fullWidth
            select
            multiple
            value={selectedTimeSlots}
            onChange={handleTimeSlotChange}
            InputLabelProps={{ style: { paddingBottom: "8px" } }}
            SelectProps={{
              renderValue: (selected) => {
                const selectedArray = Array.isArray(selected)
                  ? selected
                  : [selected];

                return (
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {selectedArray.includes(allTimeSlotsValue) ? (
                      <Chip
                        key={allTimeSlotsValue}
                        label={allTimeSlotsValue}
                        style={{ margin: 2 }}
                      />
                    ) : (
                      selectedArray.map((value) => (
                        <Chip key={value} label={value} style={{ margin: 2 }} />
                      ))
                    )}
                  </div>
                );
              },
            }}
          >
            <MenuItem value={allTimeSlotsValue}>{allTimeSlotsValue}</MenuItem>
            {timeSlots.map((slot) => (
              <MenuItem key={slot} value={slot}>
                {slot}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={isSubmitting}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
      {/* Display the Alert when isAlertOpen is true */}
      <Modal open={openModal} onClose={handleCloseModal}>
        <div className="modal-container">
          <h2>Status</h2>
          <p>{errorAlert}</p>
          <Button onClick={handleCloseModal}>OK</Button>
        </div>
      </Modal>
    </Grid>
  );
};

export default Addheavytrainer;