import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Container,
  Paper,
  Typography,
  Grid,
  Divider,
} from "@mui/material";
import { db } from "../../../Authentication/firbase.config";
import { doc, getDoc } from "firebase/firestore";

const ViewbiketrainerDetails = () => {
  const { id } = useParams();
  const [bikeDetails, setBikeDetails] = useState(null);

  useEffect(() => {
    const fetchBikeDetails = async () => {
      try {
        const bikeDocRef = doc(db, "bikes", id);
        const bikeDocSnap = await getDoc(bikeDocRef);

        if (bikeDocSnap.exists()) {
          setBikeDetails(bikeDocSnap.data());
        } else {
          console.error("Bike not found");
        }
      } catch (error) {
        console.error("Error fetching bike details:", error);
      }
    };

    fetchBikeDetails();
  }, [id]);

  if (!bikeDetails) {
    return null;
  }

  return (
    <Container>
      <Paper elevation={3} sx={{ padding: 2, margin: 2 }}>
        <Typography variant="h4" gutterBottom>
          Bike Details
        </Typography>
        <Divider />
        <Grid container spacing={2} marginTop={2} sx={{ padding: 2 }}>
          <Grid item xs={12} md={6}>
            <Typography variant="h5" sx={{ marginBottom: 1 }}>
              Bike Image
            </Typography>
            <img
              src={bikeDetails.vehicleImage}
              alt="Bike"
              style={{ width: "200px", borderRadius: "8px" }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h5" sx={{ marginBottom: 1 }}>
              Driver Image
            </Typography>
            <img
              src={bikeDetails.DriverImage}
              alt="Driver"
              style={{ maxWidth: "100%", borderRadius: "8px" }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ display: "block", padding: 1 }}>
              <span style={{ fontWeight: "bold" }}>Driver Name:</span>{" "}
              {bikeDetails.DriverName}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ display: "block", padding: 1 }}>
              <span style={{ fontWeight: "bold" }}>Phone Number:</span>{" "}
              {bikeDetails.PhoneNumber}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ display: "block", padding: 1 }}>
              <span style={{ fontWeight: "bold" }}>Bike Name:</span>{" "}
              {bikeDetails.VehicleName}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ display: "block", padding: 1 }}>
              <span style={{ fontWeight: "bold" }}>Bike Category:</span>{" "}
              {bikeDetails.VehicleCategory}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ display: "block", padding: 1 }}>
              <span style={{ fontWeight: "bold" }}>Bike Number:</span>{" "}
              {bikeDetails.VehicleNumber}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ display: "block", padding: 1 }}>
              <span style={{ fontWeight: "bold" }}>Location:</span>{" "}
              {bikeDetails.Location}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default ViewbiketrainerDetails;
