import "./sidebar.scss";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import TwoWheelerIcon from "@mui/icons-material/TwoWheeler";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import EventSeatIcon from "@mui/icons-material/EventSeat"; // Step 1
import DescriptionIcon from "@mui/icons-material/Description"; // Step 1
import { Link } from "react-router-dom";
import Learngaadiicon from "../../../Images/Learngaadiicon.png";

const Sidebar = () => {
  return (
    <div className="sidebar">
      <div className="top">
        <span className="logo">
          <img
            src={Learngaadiicon}
            alt="LearnGaadi123 Logo"
            style={{ height: "50px" }}
          />
        </span>
      </div>
      <hr />
      <div className="center">
        <ul>
          <p className="title">MAIN</p>

          <Link to="/home" style={{ textDecoration: "none" }}>
            <li>
              <DashboardIcon className="icon" />
              <span>Dashboard</span>
            </li>
          </Link>
          <p className="title">LISTS</p>
          <Link to="users" style={{ textDecoration: "none" }}>
            <li>
              <PersonOutlineIcon className="icon" />
              <span>Users</span>
            </li>
          </Link>
          <Link to="biketrainers" style={{ textDecoration: "none" }}>
            <li>
              <TwoWheelerIcon className="icon" />
              <span>Bike Trainers</span>
            </li>
          </Link>
          <Link to="cartrainers" style={{ textDecoration: "none" }}>
            <li>
              <DriveEtaIcon className="icon" />
              <span>Car Trainers</span>
            </li>
          </Link>
          <Link to="heavytrainers" style={{ textDecoration: "none" }}>
            <li>
              <LocalShippingIcon className="icon" />
              <span>Heavy-Vehicle Trainers</span>
            </li>
          </Link>
          <p className="title">REPORTS</p>
          <Link to="driverreports" style={{ textDecoration: "none" }}>
            <li>
              <EventSeatIcon className="icon" />
              <span>Driver Reports</span>
            </li>
          </Link>

          <p className="title">REQUESTS & ENQUIRIES</p>
          <Link to="requests" style={{ textDecoration: "none" }}>
            <li>
              <HelpOutlineIcon className="icon" />
              <span>Slot Requests</span>
            </li>
          </Link>
          <Link to="enquiries" style={{ textDecoration: "none" }}>
            <li>
              <QuestionAnswerIcon className="icon" />
              <span>Enquiries</span>
            </li>
          </Link>
          <Link to="appquery" style={{ textDecoration: "none" }}>
            <li>
              <SupportAgentIcon className="icon" />
              <span>App Query</span>
            </li>
          </Link>
          <p className="title">SCHEDULES</p>
          <Link to="updateschedule" style={{ textDecoration: "none" }}>
            <li>
              <DescriptionIcon className="icon" />
              <span>Update Schedule</span>
            </li>
          </Link>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
