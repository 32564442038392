import React, { useState } from "react";
import {
  Container,
  Typography,
  Paper,
  Grid,
  TextField,
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Stack,
} from "@mui/material";
import Appbar from "../components/appbar";
import { ThemeProvider } from "@mui/system";
import theme from "../styles/theme";
import AppDrawer from "../components/drawer";
import SearchBox from "../components/search";
import { useNavigate } from "react-router-dom";
import { db } from "../../Authentication/firbase.config";
import { addDoc, collection } from "firebase/firestore";

const Contactus = () => {


  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [openModal, setOpenModal] = useState(false);
    const navigate = useNavigate();

    // const [openModal, setOpenModal] = useState(false);

    const handleOpenModal = () => {
      setOpenModal(true);
    };

    const handleCloseModal = () => {
      setOpenModal(false);
      navigate("/");
    };

   
  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const docRef = await addDoc(collection(db, "enquery"), {
        name,
        email,
        phone,
        message,
      });

      console.log("Document written with ID: ", docRef.id);
      handleOpenModal();
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container
        disableGutters
        maxWidth="xl"
        sx={{
          background: "#fff",
        }}
      >
        <Stack>
          <Appbar />
          <Container maxWidth="xl" sx={{ mt: 4, backgroundColor:"#dcf2c9" }}>
      <Typography variant="h4" align="center" sx={{ mb: 4 }}>
        Contact Us
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper
            sx={{
              padding: 2,
              backgroundColor: "#f5f5f5",
              borderRadius: "10px",
            }}
          >
            <Typography variant="body1">
              <strong>Address:</strong> No. 2965, Mahakavi Kuvempu Rd, Near
              Metro Station, Mariappanapalya, Gayatrinagar, Rajajinagar,
              Bengaluru, Karnataka 560010
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
              <strong>Contact No.:</strong> 098860 60122
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
              <strong>Email:</strong> info@learngaadi.com
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper
            sx={{
              padding: 2,
              backgroundColor: "#f5f5f5",
              borderRadius: "10px",
            }}
          >
            <Typography variant="h6" sx={{ mb: 2 }}>
              Contact Form
            </Typography>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Name"
                    variant="outlined"
                    required
                    value={name}
                  onChange={(e) => setName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Email"
                    variant="outlined"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Phone"
                    variant="outlined"
                    required
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Message"
                    multiline
                    rows={4}
                    variant="outlined"
                    required
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="center">
                    <Button variant="contained" color="primary" type="submit">
                      Submit
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Grid>
      </Grid>
      {/* Modal */}
      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>Contact Form Submitted</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Thank you for submitting the form. We have received your query and will get back to you soon.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
          <SearchBox />
          <AppDrawer />
        </Stack>
      </Container>
    </ThemeProvider>
  );
}

export default Contactus;
