import { Container, Stack } from "@mui/material";
import Appbar from "../components/appbar";
import { ThemeProvider } from "@mui/system";
import theme from "../styles/theme";
import Banner from "../components/appbar/banner";
import AppDrawer from "../components/drawer";
import SearchBox from "../components/search";

function Aboutus() {
  return (
    <ThemeProvider theme={theme}>
      <Container
        disableGutters
        maxWidth="xl"
        sx={{
          background: "#fff",
        }}
      >
        <Stack>
            <Appbar />
            <Banner />
            <SearchBox />
            <AppDrawer />
        </Stack>
      </Container>
    </ThemeProvider>
  );
}

export default Aboutus;
