import { Button } from "@mui/material";
import {
  ActionIconsContainerDesktop,
  ActionIconsContainerMobile,
  MyList,
} from "../../styles/appbar";
import { Colors } from "../../styles/theme";
import { useNavigate } from "react-router-dom";

export default function Actions({ matches }) {
  const navigate = useNavigate();
  const login = () => {
    navigate("/login");
  };
  const Component = matches
    ? ActionIconsContainerMobile
    : ActionIconsContainerDesktop;

  return (
    <Component>
      <MyList type="row">
        <Button
          variant="text"
          color="inherit"
          sx={{
            display: "flex",
            justifyContent: "center",
            color: matches && Colors.white,
          }}
          onClick={login}
        >
          Login
        </Button>
      </MyList>
    </Component>
  );
}
