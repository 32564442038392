import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { AppbarContainer, MyList } from "../../styles/appbar";
import SearchIcon from "@mui/icons-material/Search";
import Actions from "./actions";
import { useUIContext } from "../../context/ui";
import Learngaadiicon from "../../../Images/Learngaadiicon.png";
import { useNavigate } from "react-router-dom";

export default function AppbarDesktop({ matches }) {
  const navigate = useNavigate();
  const { setShowSearchBox } = useUIContext();

  const handleHome = async () => {
    navigate("/"); // Navigate to the Home page
  };

  const handleAboutus = async () => {
    navigate("/aboutus"); // Navigate to the Aboutus page
  };

  const handleServices = async () => {
    navigate("/services"); // Navigate to the Services page
  };

  const handleContact = async () => {
    navigate("/contactus"); // Navigate to the ContactUs page
  };

  return (
    <AppbarContainer>
      {/* <AppbarHeader variant="h4">LearnGaadi</AppbarHeader> */}
      <img
        src={Learngaadiicon}
        alt="LearnGaadi123 Logo"
        style={{
          height: "100px",
          width: "120px",
          marginLeft: "10px",
          marginRight: "120px",
          objectFit: "contain",
        }}
      />
      <MyList type="row">
        <ListItemText
          onClick={handleHome}
          style={{ cursor: "pointer" }}
          primary="Home"
        />
        <ListItemText
          onClick={handleServices}
          style={{ cursor: "pointer" }}
          primary="Services"
        />
        <ListItemText
          onClick={handleAboutus}
          style={{ cursor: "pointer" }}
          primary="About us"
        />
        <ListItemText
          onClick={handleContact}
          style={{ cursor: "pointer" }}
          primary="Contact us"
        />
        <ListItemButton
          onClick={() => setShowSearchBox(true)}
          sx={{ marginRight: "40px" }}
        >
          <ListItemIcon sx={{ width: "100px" }}>
            <SearchIcon />
          </ListItemIcon>
        </ListItemButton>
      </MyList>
      <Actions matches={matches} />
    </AppbarContainer>
  );
}
