import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Container,
  Paper,
  Typography,
  Grid,
  Divider,
} from "@mui/material";
import { db } from "../../../Authentication/firbase.config";
import { doc, getDoc } from "firebase/firestore";

const ViewcartrainerDetails = () => {
  const { id } = useParams();
  const [carDetails, setCarDetails] = useState(null);

  useEffect(() => {
    const fetchCarDetails = async () => {
      try {
        const carDocRef = doc(db, "cars", id);
        const carDocSnap = await getDoc(carDocRef);

        if (carDocSnap.exists()) {
          setCarDetails(carDocSnap.data());
        } else {
          console.error("Car not found");
        }
      } catch (error) {
        console.error("Error fetching car details:", error);
      }
    };

    fetchCarDetails();
  }, [id]);

  if (!carDetails) {
    return null;
  }

  return (
    <Container>
      <Paper elevation={3} sx={{ padding: 2, margin: 2 }}>
        <Typography variant="h4" gutterBottom>
          Car Details
        </Typography>
        <Divider />
        <Grid container spacing={2} marginTop={2} sx={{ padding: 2 }}>
          <Grid item xs={12} md={6}>
            <Typography variant="h5" sx={{ marginBottom: 1 }}>
              Car Image
            </Typography>
            <img
              src={carDetails.vehicleImage}
              alt="Car"
              style={{ maxWidth: "100%", borderRadius: "8px" }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h5" sx={{ marginBottom: 1 }}>
              Driver Image
            </Typography>
            <img
              src={carDetails.DriverImage}
              alt="Driver"
              style={{ maxWidth: "100%", borderRadius: "8px" }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ display: "block", padding: 1 }}>
              <span style={{ fontWeight: "bold" }}>Driver Name:</span>{" "}
              {carDetails.DriverName}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ display: "block", padding: 1 }}>
              <span style={{ fontWeight: "bold" }}>Phone Number:</span>{" "}
              {carDetails.PhoneNumber}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ display: "block", padding: 1 }}>
              <span style={{ fontWeight: "bold" }}>Car Name:</span>{" "}
              {carDetails.VehicleName}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ display: "block", padding: 1 }}>
              <span style={{ fontWeight: "bold" }}>Car Category:</span>{" "}
              {carDetails.VehicleCategory}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ display: "block", padding: 1 }}>
              <span style={{ fontWeight: "bold" }}>Car Number:</span>{" "}
              {carDetails.VehicleNumber}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ display: "block", padding: 1 }}>
              <span style={{ fontWeight: "bold" }}>Location:</span>{" "}
              {carDetails.Location}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default ViewcartrainerDetails;
