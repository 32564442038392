export const products = [
    {
        id: 1,
        head: "Bike",
        name: "Bike",
        description: "Bike is a two-wheeler semi automatic transport vehicle. Bike has wheels containing either spokes or alloy. Bike runs with the help of petrol(mainly) or diesel. Bike has lights,horn,mirror etc for ease of driving.",
        image: "/images/products/bike1.jpg"
    },
    {
        id: 2,
        head: "Car",
        name: "Car",
        description: "A car, or an automobile, is a motor vehicle with wheels. Most definitions of cars state that they run primarily on roads, seat one to eight people, have four wheels, and mainly transport people",
        image: "/images/products/car1.jpg"
    },
    {
        id: 3,
        head: "Bus",
        name: "Bus",
        description: "A bus (contracted from omnibus, with variants multibus, motorbus, autobus, etc.) is a road vehicle that carries significantly more passengers than an average car or van, but less than the average rail transport. It is most commonly used in public transport, but is also in use for charter purposes, or through private ownership. Although the average bus carries between 30 and 100 passengers, some buses have a capacity of up to 300 passengers",
        image: "/images/products/bus.1.jpg"
    },
    {
        id: 4,
        head: "Truck",
        name: "Truck",
        description: "A truck or lorry is a motor vehicle designed to transport freight, carry specialized payloads, or perform other utilitarian work. Trucks vary greatly in size, power, and configuration, but the vast majority feature body-on-frame construction, with a cabin that is independent of the payload portion of the vehicle. Smaller varieties may be mechanically similar to some automobiles. Commercial trucks can be very large and powerful and may be configured to be mounted with specialized equipment, such as in the case of refuse trucks, fire trucks, concrete mixers, and suction excavators. In American English, a commercial vehicle without a trailer or other articulation is formally a straight truck while one designed specifically to pull a trailer is not a truck but a tractor.",
        image: "/images/products/truck1.jpeg"
    },
    {
        id: 5,
        head: "Semi - Two wheeler",
        name: "Scooty",
        description: "Scooty was initially conceived of as a Scooter for both sexes. However sales figures over the first two years indicated that more women were buying the Scooty than men. In 1996, Scooty went through a repositioning exercise to target women. Though it was seen as a risk with the large majority of Indian two-wheeler riders being male, TVS chose the strategy to tap the nascent segment of women riders",
        image: "/images/products/scooty1.jpg"
    },
    {
        id: 6,
        head: "Semi - van",
        name: "Nissan van",
        description: "The Nissan Vanette is a cabover van and pickup truck produced by the Japanese automaker Nissan from 1978 until 2011. The first two generations were engineered by Nissan's Aichi Manufacturing Division for private, personal ownership, with the last two generations built by Mazda, rebadged as Nissans and refocused as commercial vehicles, based on the Mazda Bongo. The van has also been sold as the Nissan Sunny-Vanette or Nissan Van",
        image: "/images/products/van1.jpg"
    }
]